import { React } from "react";
import { Component } from "react";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  getSingleForm,
  getAllSubmissionTotals,
  getTotalFormSubmissions,
  sendFormEmail,
  viewGeneralSubmission,
  deleteGeneralSubmission,
  assignForm,
  getClientSubmissions,
  getAgentSubmissions,
  viewClientSubmission,
  viewAgentSubmission,
  resendGeneralForm,
  rejectGeneralSubmission,
  approveGeneralSubmission,
  allGeneralRejections,
  approveClientSubmission,
  rejectClientSubmission,
  allClientRejections,
} from "./../../../config/forms_api_calls";
import {
  listActiveClients,
  listSingleClient,
} from "./../../../config/reseller_api_calls";

import { listAgents } from "./../../../config/api_calls";
import $ from "jquery";
import { AGENT_PORTAL_URL, APP_LIVE_URL } from "../../../config/config";
import DataTableExtended from "../../../components/Tables/DataTableExtended";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { FormLoader } from "../../../components/ContentLoaders/newLoaders/Form";
import FormRenderer from "../../../components/Reseller/formRender";
import Tooltip from "@material-ui/core/Tooltip";
import dateFormat, { masks } from "dateformat";
import MutextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
const animatedComponents = makeAnimated();

const auth = new HelperClass();

class Forms extends Component {
  constructor() {
    super();

    this.state = {
      errorMessage: "",
      successMessage: "",
      successMessaged: "",
      errorMessaged: "",
      errorMessageTotals: "",
      errorMessageTotalsTitle: "",
      errorMessageClients: "",
      errorMessageClientsTitle: "",
      errorMessageAgents: "",
      errorMessageAgentsTitle: "",
      emailErrorMessage: "",
      emailErrorMessageTitle: "",
      emailSuccessMessage: "",
      errorMessageDelete: "",
      successMessageDelte: "",
      mainError: "",
      tableLoader: true,
      disabled: false,
      form_accountno: "",
      form_title: "",
      form_desc: "",
      form_status: "",
      form_content: [],
      showOnDashboard: false,
      form_sequence: "1",
      form_sequence_error: "",
      btn_text: "",
      allow_multiple: false,
      is_default: false,
      activeTab: "total_submissions",
      totalSubmissions: [],
      totalClientSubmissions: [],
      clientSubmissions: [],
      agentSubmissions: [],
      assignedLoader: false,
      viewGeneralSubmission: [],
      viewClientSubmissions: [],
      viewAgentSubmissions: [],
      viewLoader: true,
      submission_id: "",
      isProcessing: false,
      total_general_submissions: 0,
      total_client_submissions: 0,
      total_agent_submissions: 0,
      total_general_completed: 0,
      total_client_completed: 0,
      total_agent_completed: 0,
      //Send Email
      emailto: "",
      emailtoname: "",
      emailsubject: "",
      emailcontent: "",
      email_button: false,
      clientColumnServices: [],
      agentColumnServices: [],
      extra_files: [],
      extra_files_clients: [],
      extra_files_agents: [],
      client_accountno: "",
      selected_client_accountno: null,
      all_active_clients: [],
      all_active_agents: [],
      selected_agent_accountno: null,
      agent_accountno: "",
      select_clients: true,
      select_agents: "clients",
      selected_emails: "",
      general_submission_id: "",
      client_submission_id: "",
      client_submission_type: "",
      agent_submission_id: "",
      errorMessageResend: "",
      successMessageResend: "",
      resendLoader: false,
      //Reject General Form
      rejectGeneralErrorMessage: "",
      rejectGeneralSuccessMessage: "",
      reject_general_button: false,
      reject_general_reason: "",
      approveGeneralErrorMessage: "",
      approveGeneralSuccessMessage: "",
      approve_general_button: false,
      allGeneralRejectionsListing: [],
      rejectionLoader: false,
      //CLIENTS
      rejectClientErrorMessage: "",
      rejectClientSuccessMessage: "",
      reject_client_button: false,
      reject_client_reason: "",
      approveClientErrorMessage: "",
      approveClientSuccessMessage: "",
      approve_client_button: false,
      allClientRejectionsListing: [],
      allAgentRejectionsListing: [],
      rejectionClientLoader: false,
      rejection_client_title: "",

      //AGENTS
      generalColumnServices: [
        {
          name: "id",
          label: "Form Title",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_client_title(value)}</div>;
            },
          },
        },
        {
          name: "submitter_name",
          label: "Sent To",
        },
        {
          name: "is_submitted",
          label: "Submitted",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_submitted(value)}</div>;
            },
          },
        },
        {
          name: "submitted_on",
          label: "Submitted On",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_date(value)}</div>;
            },
          },
        },
        {
          name: "sent_by_email",
          label: "Sent By",
        },
        {
          name: "pdf_name",
          label: "Download",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>{this.format_download_general(value, tableMeta)}</div>
              );
            },
          },
        },
        {
          name: "submission_status",
          label: "Status",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>{this.format_submission_status(value, tableMeta)}</div>
              );
            },
          },
        },
        {
          name: "id",
          label: "Actions",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            style={{ cursor: "pointer" }}
                            title="Other Actions"
                            className="dropdown-toggle btn btn-icon  btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-md dropdown-menu-right customStyle_dropdown-menu-md_forms">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <Tooltip title="View Details" placement="top">
                                  <a
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.viewGeneralSubmission(value)
                                    }
                                  >
                                    <em class="icon ni ni-card-view"></em>
                                    <span>View Details</span>
                                  </a>
                                </Tooltip>
                              </li>
                              {tableMeta.rowData[2] === 0 ? (
                                <li>
                                  <Tooltip title="Resend Form" placement="top">
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.openResendAlert(value)
                                      }
                                    >
                                      <em class="icon ni ni-emails"></em>
                                      <span>Resend</span>
                                    </a>
                                  </Tooltip>
                                </li>
                              ) : null}
                              {tableMeta.rowData[2] === 1 &&
                              tableMeta.rowData[6] === 0 ? (
                                <>
                                  <li>
                                    <Tooltip
                                      title="Approve Submission"
                                      placement="top"
                                    >
                                      <a
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          this.openApproveGeneralAlert(
                                            value,
                                            tableMeta
                                          )
                                        }
                                      >
                                        <em class="icon ni ni-done text-success"></em>
                                        <span class="text-success">
                                          Approve
                                        </span>
                                      </a>
                                    </Tooltip>
                                  </li>
                                  <li>
                                    <Tooltip
                                      title="Reject Submission"
                                      placement="top"
                                    >
                                      <a
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          this.openRejectionAlert(
                                            value,
                                            tableMeta
                                          )
                                        }
                                        class="text-danger"
                                      >
                                        <em class="icon ni ni-cross-circle text-danger"></em>
                                        <span class="text-danger">Reject</span>
                                      </a>
                                    </Tooltip>
                                  </li>
                                </>
                              ) : tableMeta.rowData[2] === 1 &&
                                tableMeta.rowData[6] === 2 ? (
                                <li>
                                  <Tooltip
                                    title="Approve Submission"
                                    placement="top"
                                  >
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.openApproveGeneralAlert(
                                          value,
                                          tableMeta
                                        )
                                      }
                                    >
                                      <em class="icon ni ni-done text-success"></em>
                                      <span class="text-success">Approve</span>
                                    </a>
                                  </Tooltip>
                                </li>
                              ) : tableMeta.rowData[2] === 1 &&
                                tableMeta.rowData[6] === 1 ? (
                                <li>
                                  <Tooltip
                                    title="Reject Submission"
                                    placement="top"
                                  >
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.openRejectionAlert(
                                          value,
                                          tableMeta
                                        )
                                      }
                                      class="text-danger"
                                    >
                                      <em class="icon ni ni-cross-circle text-danger"></em>
                                      <span class="text-danger">Reject</span>
                                    </a>
                                  </Tooltip>
                                </li>
                              ) : null}

                              <li>
                                <Tooltip
                                  title="View all rejections"
                                  placement="top"
                                >
                                  <a
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.modalAllRejectionsGeneral(
                                        value,
                                        tableMeta
                                      )
                                    }
                                    class="text-danger"
                                  >
                                    <em class="icon ni ni-cross-circle"></em>
                                    <span>View Rejections</span>
                                  </a>
                                </Tooltip>
                              </li>
                              {tableMeta.rowData[8] &&
                              tableMeta.rowData[8] === 1 ? (
                                <li>
                                  <Tooltip title="Link Only" placement="top">
                                    <a
                                      style={{ cursor: "pointer" }}
                                      href={tableMeta.rowData[9]}
                                      target="_blank"
                                      class="text-danger"
                                    >
                                      <em class="icon ni ni-link-alt text-warning"></em>
                                      <span class="text-warning">
                                        Link Only
                                      </span>
                                    </a>
                                  </Tooltip>
                                </li>
                              ) : null}
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
        {
          name: "link_form",
          label: "Link Only",
          options: {
            display: false,
          },
        },
        {
          name: "external_link",
          label: "Link",
          options: {
            display: false,
          },
        },
        {
          name: "form_token",
          label: "Token",
          options: {
            display: false,
          },
        },
      ],
    };
  }

  format_date = (value, tableMeta) => {
    let date = value;
    if (date && date !== "") {
      return dateFormat(date, "mmmm dS, yyyy");
    } else {
      return "---";
    }
  };

  async componentDidMount() {
    let form_accountno = this.props.props.match.params.id;
    this.setState({
      errorMessage: "",
      successMessage: "",
      form_accountno: form_accountno,
    });
    const servicesResponce = await getSingleForm(
      auth.getAccount(),
      auth.getToken(),
      form_accountno
    );
    console.log("servicesResponce: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let form_sequence =
        servicesResponce.data.data[0]["form_sequence"] &&
        servicesResponce.data.data[0]["form_sequence"] > 0
          ? servicesResponce.data.data[0]["form_sequence"]
          : "1";
      let btn_text =
        servicesResponce.data.data[0]["btn_text"] &&
        servicesResponce.data.data[0]["btn_text"] !== ""
          ? servicesResponce.data.data[0]["btn_text"]
          : "";
      this.setState({
        form_title: servicesResponce.data.data[0]["form_title"],
        form_desc: servicesResponce.data.data[0]["form_desc"],
        form_content: JSON.parse(servicesResponce.data.data[0]["form_content"]),
        showOnDashboard: servicesResponce.data.data[0]["show_on_dashboard"],
        allow_multiple: servicesResponce.data.data[0]["allow_multiple"],
        is_default: servicesResponce.data.data[0]["is_default"],
        form_status: servicesResponce.data.data[0]["status"],
        form_sequence: form_sequence,
        btn_text: btn_text,
        tableLoader: false,
      });
    } else {
      //window.location.replace("/error");
    }
    this.getAllSubmissionTotals();
    this.getTotalSubmissions();
  }

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  changeActiveTab = (tab) => {
    this.setState({
      activeTab: tab,
      assignedLoader: true,
    });
  };

  getAllSubmissionTotals = async () => {
    const { form_accountno } = this.state;
    const servicesResponse = await getAllSubmissionTotals(
      auth.getAccount(),
      auth.getToken(),
      form_accountno
    );

    console.log("getAllSubmissionTotals: ", servicesResponse.data);

    if (
      servicesResponse.data.status === 403 ||
      servicesResponse.data.errors === "authentication missing" ||
      servicesResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponse.data.status === 404) {
    } else if (
      servicesResponse.data.status === 200 &&
      servicesResponse.data.message === "success"
    ) {
      this.setState({
        total_general_submissions:
          servicesResponse.data.total_general_submissions,
        total_client_submissions:
          servicesResponse.data.total_client_submissions,
        total_agent_submissions: servicesResponse.data.total_agent_submissions,
        total_general_completed: servicesResponse.data.total_general_completed,
        total_client_completed: servicesResponse.data.total_client_completed,
        total_agent_completed: servicesResponse.data.total_agent_completed,
      });

      //window.$("#modalAssignForm").modal("show");
    } else {
    }
  };

  getTotalSubmissions = async () => {
    const { form_accountno } = this.state;
    const servicesResponse = await getTotalFormSubmissions(
      auth.getAccount(),
      auth.getToken(),
      form_accountno
    );

    console.log("getTotalSubmissions.data.data: ", servicesResponse.data);

    if (
      servicesResponse.data.status === 403 ||
      servicesResponse.data.errors === "authentication missing" ||
      servicesResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponse.data.status === 404) {
      this.setState({
        errorMessageTotalsTitle: "No Submission",
        errorMessageTotals: "There are no submissions right now.",
        assignedLoader: false,
      });
    } else if (
      servicesResponse.data.status === 200 &&
      servicesResponse.data.message === "success"
    ) {
      this.setState({
        errorMessageTotalsTitle: "",
        errorMessageTotals: "",
        totalSubmissions: servicesResponse.data.data,
        assignedLoader: false,
      });

      //window.$("#modalAssignForm").modal("show");
    } else {
      this.setState({
        errorMessageTotalsTitle: "No Submission",
        errorMessageTotals: "There are no submissions right now.",
        assignedLoader: false,
      });
    }
  };

  getClientSubmissions = async () => {
    const { form_accountno } = this.state;
    const servicesResponse = await getClientSubmissions(
      auth.getAccount(),
      auth.getToken(),
      form_accountno
    );

    console.log("getClientSubmissions: ", servicesResponse.data);

    if (
      servicesResponse.data.status === 403 ||
      servicesResponse.data.errors === "authentication missing" ||
      servicesResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponse.data.status === 404) {
      this.setState({
        errorMessageClientsTitle: "No Submission",
        errorMessageClients: "There are no submissions right now.",
        assignedLoader: false,
      });
    } else if (
      servicesResponse.data.status === 200 &&
      servicesResponse.data.message === "success"
    ) {
      this.setState({
        errorMessageClientsTitle: "",
        errorMessageClients: "",
        totalClientSubmissions: servicesResponse.data.data,
        clientColumnServices: [
          {
            name: "id",
            label: "Form Title",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return <div>{this.format_client_title(value)}</div>;
              },
            },
          },
          {
            name: "submitter_name",
            label: "Sent To",
          },
          {
            name: "is_submitted",
            label: "Submitted",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return <div>{this.format_submitted(value)}</div>;
              },
            },
          },
          {
            name: "submitted_on",
            label: "Submitted On",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return <div>{this.format_date(value)}</div>;
              },
            },
          },
          {
            name: "sent_by_email",
            label: "Sent By",
          },
          {
            name: "pdf_name",
            label: "Download",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return <div>{this.format_download(value, tableMeta)}</div>;
              },
            },
          },
          {
            name: "submission_status",
            label: "Status",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <div>{this.format_submission_status(value, tableMeta)}</div>
                );
              },
            },
          },
          {
            name: "id",
            label: "Actions",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <div>
                    <div
                      className="nk-tb-col nk-tb-col-tools"
                      style={{ padding: "0px" }}
                    >
                      <ul className="nk-tb-actions gx-1">
                        <li>
                          <div className="drodown">
                            <a
                              style={{ cursor: "pointer" }}
                              title="Other Actions"
                              className="dropdown-toggle btn btn-icon  btn-outline-light"
                              data-toggle="dropdown"
                            >
                              <em className="icon ni ni-more-h"></em>
                            </a>
                            <div className="dropdown-menu dropdown-menu-md dropdown-menu-right customStyle_dropdown-menu-md_forms">
                              <ul className="link-list-opt no-bdr">
                                <li>
                                  <Tooltip title="View Details" placement="top">
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.viewClientSubmission(
                                          value,
                                          tableMeta
                                        )
                                      }
                                    >
                                      <em class="icon ni ni-card-view"></em>
                                      <span>View Details</span>
                                    </a>
                                  </Tooltip>
                                </li>
                                {tableMeta.rowData[2] === 1 &&
                                tableMeta.rowData[6] === 0 ? (
                                  <>
                                    <li>
                                      <Tooltip
                                        title="Approve Submission"
                                        placement="top"
                                      >
                                        <a
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            this.openApproveClientAlert(
                                              value,
                                              "clients",
                                              tableMeta
                                            )
                                          }
                                        >
                                          <em class="icon ni ni-done text-success"></em>
                                          <span class="text-success">
                                            Approve
                                          </span>
                                        </a>
                                      </Tooltip>
                                    </li>
                                    <li>
                                      <Tooltip
                                        title="Reject Submission"
                                        placement="top"
                                      >
                                        <a
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            this.openRejectionClientAlert(
                                              value,
                                              "clients",
                                              tableMeta
                                            )
                                          }
                                          class="text-danger"
                                        >
                                          <em class="icon ni ni-cross-circle text-danger"></em>
                                          <span class="text-danger">
                                            Reject
                                          </span>
                                        </a>
                                      </Tooltip>
                                    </li>
                                  </>
                                ) : tableMeta.rowData[2] === 1 &&
                                  tableMeta.rowData[6] === 2 ? (
                                  <li>
                                    <Tooltip
                                      title="Approve Submission"
                                      placement="top"
                                    >
                                      <a
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          this.openApproveClientAlert(
                                            value,
                                            "clients",
                                            tableMeta
                                          )
                                        }
                                      >
                                        <em class="icon ni ni-done text-success"></em>
                                        <span class="text-success">
                                          Approve
                                        </span>
                                      </a>
                                    </Tooltip>
                                  </li>
                                ) : tableMeta.rowData[2] === 1 &&
                                  tableMeta.rowData[6] === 1 ? (
                                  <li>
                                    <Tooltip
                                      title="Reject Submission"
                                      placement="top"
                                    >
                                      <a
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          this.openRejectionClientAlert(
                                            value,
                                            "clients",
                                            tableMeta
                                          )
                                        }
                                        class="text-danger"
                                      >
                                        <em class="icon ni ni-cross-circle text-danger"></em>
                                        <span class="text-danger">Reject</span>
                                      </a>
                                    </Tooltip>
                                  </li>
                                ) : null}

                                <li>
                                  <Tooltip
                                    title="View all rejections"
                                    placement="top"
                                  >
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.modalAllRejectionsClient(
                                          value,
                                          "clients",
                                          tableMeta
                                        )
                                      }
                                      class="text-danger"
                                    >
                                      <em class="icon ni ni-cross-circle"></em>
                                      <span>View Rejections</span>
                                    </a>
                                  </Tooltip>
                                </li>
                                {tableMeta.rowData[8] &&
                                tableMeta.rowData[8] === 1 ? (
                                  <li>
                                    <Tooltip title="Link Only" placement="top">
                                      <a
                                        style={{ cursor: "pointer" }}
                                        href={tableMeta.rowData[9]}
                                        target="_blank"
                                        class="text-danger"
                                      >
                                        <em class="icon ni ni-link-alt text-warning"></em>
                                        <span class="text-warning">
                                          Link Only
                                        </span>
                                      </a>
                                    </Tooltip>
                                  </li>
                                ) : null}
                                <li>
                                  <Tooltip
                                    title="Delete Submission"
                                    placement="top"
                                  >
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.delete_archived_details(
                                          value,
                                          tableMeta
                                        )
                                      }
                                      disabled={
                                        this.props.is_msa_signed === "No" ||
                                        this.props.defaultSettings === "missing"
                                          ? true
                                          : false
                                      }
                                    >
                                      <em className="icon ni ni-edit"></em>
                                      <span>Delete</span>
                                    </a>
                                  </Tooltip>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                );
              },
            },
          },
          {
            name: "link_form",
            label: "Link Only",
            options: {
              display: false,
            },
          },
          {
            name: "external_link",
            label: "Link",
            options: {
              display: false,
            },
          },
          {
            name: "form_token",
            label: "Token",
            options: {
              display: false,
            },
          },
        ],
        assignedLoader: false,
      });

      //window.$("#modalAssignForm").modal("show");
    } else {
      this.setState({
        errorMessageClientsTitle: "No Submission",
        errorMessageClients: "There are no submissions right now.",
        assignedLoader: false,
      });
    }
  };

  format_client_title = (value) => {
    return this.state.form_title;
  };

  format_approved = (value) => {
    if (value === "Yes") {
      return (
        <Tooltip title="This agreement is approved." placement="top">
          <span className="badge badge-outline-success">
            <em class="icon ni ni-done"></em> Yes
          </span>
        </Tooltip>
      );
    } else {
      return (
        <span className="badge badge-outline-light">
          <em className="icon ni ni-caution"></em> No
        </span>
      );
    }
  };

  format_submitted = (value) => {
    if (value === 1) {
      return (
        <span className="badge badge-outline-success">
          <em class="icon ni ni-done"></em> Yes
        </span>
      );
    } else {
      return (
        <span className="badge badge-outline-light">
          <em className="icon ni ni-caution"></em> No
        </span>
      );
    }
  };

  format_submission_status = (value, tableMeta) => {
    const is_submitted = tableMeta.rowData[2];
    if (is_submitted === 1) {
      if (value === 0) {
        return (
          <span className="badge badge-outline-primary">
            <em class="icon ni ni-done"></em> Pending Approval
          </span>
        );
      } else if (value === 1) {
        return (
          <span className="badge badge-outline-success">
            <em className="icon ni ni-caution"></em> Approved
          </span>
        );
      } else if (value === 2) {
        return (
          <span className="badge badge-outline-danger">
            <em className="icon ni ni-caution"></em> Rejected
          </span>
        );
      } else {
        return (
          <span className="badge badge-outline-primary">
            <em className="icon ni ni-caution"></em> Pending Approval
          </span>
        );
      }
    } else {
      if (value === 2) {
        return (
          <span className="badge badge-outline-danger">
            <em className="icon ni ni-caution"></em> Rejected
          </span>
        );
      } else {
        return "---";
      }
    }
  };

  format_download = (value, tableMeta) => {
    //console.log("format_download value: ", value);
    let form_token = tableMeta.rowData[10];
    if (value && value !== "") {
      return (
        <>
          <a
            onClick={() => this.downloadPdf(value, this.state.form_title)}
            title="Download Agreement"
            style={{
              cursor: "pointer",
            }}
          >
            <span className="badge badge-outline-primary">
              <em className="icon ni ni-download"></em>
            </span>
          </a>
          <a
            onClick={() =>
              this.addMenuHistory("/forms/view_client_submission/" + form_token)
            }
            title="Submission View"
            style={{
              cursor: "pointer",
              marginLeft: "5px",
            }}
          >
            <span className="badge badge-outline-primary">
              <em class="icon ni ni-table-view"></em>
            </span>
          </a>
        </>
      );
    } else {
      return <span className="badge badge-outline-light">---</span>;
    }
  };

  format_download_agent = (value, tableMeta) => {
    //console.log("format_download value: ", value);
    let form_token = tableMeta.rowData[10];
    if (value && value !== "") {
      return (
        <>
          <a
            onClick={() => this.downloadPdf(value, this.state.form_title)}
            title="Download Agreement"
            style={{
              cursor: "pointer",
            }}
          >
            <span className="badge badge-outline-primary">
              <em className="icon ni ni-download"></em>
            </span>
          </a>
          <a
            onClick={() =>
              this.addMenuHistory("/forms/view_agent_submission/" + form_token)
            }
            title="Submission View"
            style={{
              cursor: "pointer",
              marginLeft: "5px",
            }}
          >
            <span className="badge badge-outline-primary">
              <em class="icon ni ni-table-view"></em>
            </span>
          </a>
        </>
      );
    } else {
      return <span className="badge badge-outline-light">---</span>;
    }
  };

  format_download_general = (value, tableMeta) => {
    // console.log("format_download value: ", value);
    let form_token = tableMeta.rowData[10];
    if (value && value !== "") {
      return (
        <>
          <a
            onClick={() => this.downloadPdfGeneral(value)}
            title="Download PDF"
            style={{
              cursor: "pointer",
            }}
          >
            <span className="badge badge-outline-primary">
              <em className="icon ni ni-download"></em>
            </span>
          </a>
          <a
            onClick={() =>
              this.addMenuHistory("/forms/view_submission/" + form_token)
            }
            title="Submission View"
            style={{
              cursor: "pointer",
              marginLeft: "5px",
            }}
          >
            <span className="badge badge-outline-primary">
              <em class="icon ni ni-table-view"></em>
            </span>
          </a>
        </>
      );
    } else {
      return <span className="badge badge-outline-light">---</span>;
    }
  };

  getAgentSubmissions = async () => {
    const { form_accountno } = this.state;
    const servicesResponse = await getAgentSubmissions(
      auth.getAccount(),
      auth.getToken(),
      form_accountno
    );

    console.log("getAgentSubmissions.data.data: ", servicesResponse.data);

    if (
      servicesResponse.data.status === 403 ||
      servicesResponse.data.errors === "authentication missing" ||
      servicesResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponse.data.status === 404) {
      this.setState({
        errorMessageAgentsTitle: "No Submission",
        errorMessageAgents: "There are no submissions right now.",
        assignedLoader: false,
      });
    } else if (
      servicesResponse.data.status === 200 &&
      servicesResponse.data.message === "success"
    ) {
      this.setState({
        errorMessageAgentsTitle: "",
        errorMessageAgents: "",
        agentSubmissions: servicesResponse.data.data,
        assignedLoader: false,
        agentColumnServices: [
          {
            name: "id",
            label: "Form Title",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return <div>{this.format_client_title(value)}</div>;
              },
            },
          },
          {
            name: "submitter_name",
            label: "Sent To",
          },
          {
            name: "is_submitted",
            label: "Submitted",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return <div>{this.format_submitted(value)}</div>;
              },
            },
          },
          {
            name: "submitted_on",
            label: "Submitted On",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return <div>{this.format_date(value)}</div>;
              },
            },
          },
          {
            name: "sent_by_email",
            label: "Sent By",
          },
          {
            name: "pdf_name",
            label: "Download",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return <div>{this.format_download_agent(value, tableMeta)}</div>;
              },
            },
          },
          {
            name: "submission_status",
            label: "Status",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <div>{this.format_submission_status(value, tableMeta)}</div>
                );
              },
            },
          },
          {
            name: "id",
            label: "Actions",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <div>
                    <div
                      className="nk-tb-col nk-tb-col-tools"
                      style={{ padding: "0px" }}
                    >
                      <ul className="nk-tb-actions gx-1">
                        <li>
                          <div className="drodown">
                            <a
                              style={{ cursor: "pointer" }}
                              title="Other Actions"
                              className="dropdown-toggle btn btn-icon  btn-outline-light"
                              data-toggle="dropdown"
                            >
                              <em className="icon ni ni-more-h"></em>
                            </a>
                            <div className="dropdown-menu dropdown-menu-md dropdown-menu-right customStyle_dropdown-menu-md_forms">
                              <ul className="link-list-opt no-bdr">
                                <li>
                                  <Tooltip title="View Details" placement="top">
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.viewAgentSubmission(
                                          value,
                                          tableMeta
                                        )
                                      }
                                    >
                                      <em class="icon ni ni-card-view"></em>
                                      <span>View Details</span>
                                    </a>
                                  </Tooltip>
                                </li>
                                {tableMeta.rowData[2] === 1 &&
                                tableMeta.rowData[6] === 0 ? (
                                  <>
                                    <li>
                                      <Tooltip
                                        title="Approve Submission"
                                        placement="top"
                                      >
                                        <a
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            this.openApproveClientAlert(
                                              value,
                                              "agents",
                                              tableMeta
                                            )
                                          }
                                        >
                                          <em class="icon ni ni-done text-success"></em>
                                          <span class="text-success">
                                            Approve
                                          </span>
                                        </a>
                                      </Tooltip>
                                    </li>
                                    <li>
                                      <Tooltip
                                        title="Reject Submission"
                                        placement="top"
                                      >
                                        <a
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            this.openRejectionClientAlert(
                                              value,
                                              "agents",
                                              tableMeta
                                            )
                                          }
                                          class="text-danger"
                                        >
                                          <em class="icon ni ni-cross-circle text-danger"></em>
                                          <span class="text-danger">
                                            Reject
                                          </span>
                                        </a>
                                      </Tooltip>
                                    </li>
                                  </>
                                ) : tableMeta.rowData[2] === 1 &&
                                  tableMeta.rowData[6] === 2 ? (
                                  <li>
                                    <Tooltip
                                      title="Approve Submission"
                                      placement="top"
                                    >
                                      <a
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          this.openApproveClientAlert(
                                            value,
                                            "agents",
                                            tableMeta
                                          )
                                        }
                                      >
                                        <em class="icon ni ni-done text-success"></em>
                                        <span class="text-success">
                                          Approve
                                        </span>
                                      </a>
                                    </Tooltip>
                                  </li>
                                ) : tableMeta.rowData[2] === 1 &&
                                  tableMeta.rowData[6] === 1 ? (
                                  <li>
                                    <Tooltip
                                      title="Reject Submission"
                                      placement="top"
                                    >
                                      <a
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          this.openRejectionClientAlert(
                                            value,
                                            "agents",
                                            tableMeta
                                          )
                                        }
                                        class="text-danger"
                                      >
                                        <em class="icon ni ni-cross-circle text-danger"></em>
                                        <span class="text-danger">Reject</span>
                                      </a>
                                    </Tooltip>
                                  </li>
                                ) : null}

                                <li>
                                  <Tooltip
                                    title="View all rejections"
                                    placement="top"
                                  >
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.modalAllRejectionsClient(
                                          value,
                                          "agents",
                                          tableMeta
                                        )
                                      }
                                      class="text-danger"
                                    >
                                      <em class="icon ni ni-cross-circle"></em>
                                      <span>View Rejections</span>
                                    </a>
                                  </Tooltip>
                                </li>
                                {tableMeta.rowData[7] &&
                                tableMeta.rowData[7] === 1 ? (
                                  <li>
                                    <Tooltip title="Link Only" placement="top">
                                      <a
                                        style={{ cursor: "pointer" }}
                                        href={tableMeta.rowData[8]}
                                        target="_blank"
                                        class="text-danger"
                                      >
                                        <em class="icon ni ni-link-alt text-warning"></em>
                                        <span class="text-warning">
                                          Link Only
                                        </span>
                                      </a>
                                    </Tooltip>
                                  </li>
                                ) : null}
                                <li>
                                  <Tooltip
                                    title="Delete Submission"
                                    placement="top"
                                  >
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.delete_archived_details(
                                          value,
                                          tableMeta
                                        )
                                      }
                                      disabled={
                                        this.props.is_msa_signed === "No" ||
                                        this.props.defaultSettings === "missing"
                                          ? true
                                          : false
                                      }
                                    >
                                      <em className="icon ni ni-edit"></em>
                                      <span>Delete</span>
                                    </a>
                                  </Tooltip>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                );
              },
            },
          },
          {
            name: "link_form",
            label: "Link Only",
            options: {
              display: false,
            },
          },
          {
            name: "external_link",
            label: "Link",
            options: {
              display: false,
            },
          },

          {
            name: "form_token",
            label: "Token",
            options: {
              display: false,
            },
          },
        ],
      });

      //window.$("#modalAssignForm").modal("show");
    } else {
      this.setState({
        errorMessageAgentsTitle: "No Submission",
        errorMessageAgents: "There are no submissions right now.",
        assignedLoader: false,
      });
    }
  };

  viewGeneralSubmission = async (id) => {
    const { form_accountno } = this.state;
    this.setState({ viewLoader: true });
    const servicesResponse = await viewGeneralSubmission(
      auth.getAccount(),
      auth.getToken(),
      form_accountno,
      id
    );
    window.$("#modalViewGeneralSubmission").modal("show");
    console.log("viewGeneralSubmission: ", servicesResponse.data);

    if (
      servicesResponse.data.status === 403 ||
      servicesResponse.data.errors === "authentication missing" ||
      servicesResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponse.data.status === 404 &&
      servicesResponse.data.message === "form_not_found"
    ) {
      this.setState({
        errorMessageTotalsTitle: "No Submission",
        errorMessageTotals: "There are no submissions right now.",
        viewLoader: false,
      });
    } else if (
      servicesResponse.data.status === 200 &&
      servicesResponse.data.message === "success"
    ) {
      let extra_files = [];
      if (servicesResponse.data.data && servicesResponse.data.data.length > 0) {
        if (servicesResponse.data.data[0].extra_files) {
          extra_files = JSON.parse(servicesResponse.data.data[0].extra_files);
        }
      }
      this.setState({
        viewGeneralSubmission: servicesResponse.data.data,
        extra_files: extra_files,
        viewLoader: false,
      });

      //
    }
  };

  openResendAlert = (id, tableMeta) => {
    this.setState({
      general_submission_id: id,
      errorMessageResend: "",
      successMessageResend: "",
    });
    console.log("resendForm: ", id);
    window.$("#modalResendAlert").modal("show");
  };

  closeResendForm = async () => {
    this.setState({
      general_submission_id: "",
    });
    window.$("#modalResendAlert").modal("hide");
  };

  resendGeneralForm = async () => {
    const { form_accountno, general_submission_id } = this.state;
    this.setState({ resendLoader: true });
    const servicesResponse = await resendGeneralForm(
      auth.getAccount(),
      auth.getToken(),
      form_accountno,
      general_submission_id
    );
    console.log("resendGeneralForm: ", servicesResponse.data);
    if (
      servicesResponse.data.status === 403 ||
      servicesResponse.data.errors === "authentication missing" ||
      servicesResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponse.data.status === 404 &&
      servicesResponse.data.message === "not_found"
    ) {
      this.setState({
        errorMessageResend: "Form submission not found.",
        successMessageResend: "",
        resendLoader: false,
      });
    } else if (
      servicesResponse.data.status === 200 &&
      servicesResponse.data.message === "success"
    ) {
      this.setState({
        errorMessageResend: "",
        successMessageResend: "Form sent successfully.",
        resendLoader: false,
      });
      setTimeout(() => {
        window.$("#modalResendAlert").modal("hide");
      }, 4000);

      //
    } else {
      this.setState({
        errorMessageResend: "There is some error while resending the form.",
        successMessageResend: "",
        resendLoader: false,
      });
    }
  };

  openApproveGeneralAlert = (id, tableMeta) => {
    this.setState({
      general_submission_id: id,
      approveGeneralErrorMessage: "",
      approveGeneralSuccessMessage: "",
      approve_general_button: false,
    });
    window.$("#modalApproveGeneralAlert").modal("show");
  };

  approve_general_form = async () => {
    const { general_submission_id, form_accountno, form_title } = this.state;
    this.setState({
      approveGeneralErrorMessage: "",
      approveGeneralSuccessMessage: "",
      approve_general_button: true,
    });

    const servicesResponse = await approveGeneralSubmission(
      auth.getAccount(),
      auth.getToken(),
      form_accountno,
      general_submission_id,
      form_title
    );

    console.log("rejectGeneralSubmission: ", servicesResponse.data);

    if (
      servicesResponse.data.status === 403 ||
      servicesResponse.data.errors === "authentication missing" ||
      servicesResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponse.data.status === 400 &&
      servicesResponse.data.message === "form_not_found"
    ) {
      this.setState({
        approveGeneralErrorMessage:
          "There is some error while approving the submission.",
        approveGeneralSuccessMessage: "",
        approve_general_button: false,
      });
    } else if (
      servicesResponse.data.status === 200 &&
      servicesResponse.data.message === "success"
    ) {
      this.setState(
        {
          approveGeneralErrorMessage: "",
          approveGeneralSuccessMessage: "Submission approved successfully.",

          reject_general_reason: "",
        },
        () => {
          setTimeout(() => {
            this.getTotalSubmissions();
            window.$("#modalApproveGeneralAlert").modal("hide");
            this.setState({
              approveGeneralErrorMessage: "",
              approveGeneralSuccessMessage: "",
            });
            //window.location.reload();
          }, 3000);
        }
      );
    } else {
      this.setState({
        approve_general_button: false,
        approveGeneralErrorMessage:
          "There is some error while approving the submission.",
        approveGeneralSuccessMessage: "",
      });
    }
  };

  modalAllRejectionsGeneral = async (id, tableMeta) => {
    const { form_accountno, form_title } = this.state;
    this.setState({
      rejectGeneralErrorMessage: "",
      rejectGeneralSuccessMessage: "",
      allGeneralRejectionsListing: [],
      rejectionLoader: true,
    });
    window.$("#modalRejectionGeneral").modal("show");
    const servicesResponse = await allGeneralRejections(
      auth.getAccount(),
      auth.getToken(),
      form_accountno,
      id
    );

    console.log("allGeneralRejections: ", servicesResponse.data);

    if (
      servicesResponse.data.status === 403 ||
      servicesResponse.data.errors === "authentication missing" ||
      servicesResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponse.data.status === 404 &&
      servicesResponse.data.message === "form_not_found"
    ) {
      this.setState({
        approveGeneralErrorMessage: "Submission not found.",
        approveGeneralSuccessMessage: "",
        rejectionLoader: false,
      });
    } else if (
      servicesResponse.data.status === 404 &&
      servicesResponse.data.message === "rejection_not_found"
    ) {
      this.setState({
        approveGeneralErrorMessage:
          "There are no rejections against this submission.",
        approveGeneralSuccessMessage: "",
        rejectionLoader: false,
      });
    } else if (
      servicesResponse.data.status === 200 &&
      servicesResponse.data.message === "success"
    ) {
      this.setState({
        approveGeneralErrorMessage: "",
        approveGeneralSuccessMessage: "",

        allGeneralRejectionsListing: servicesResponse.data.data,
        rejectionLoader: false,
      });
    } else {
      this.setState({
        rejectionLoader: false,
        approveGeneralErrorMessage:
          "There is some error while getting the rejections.",
        approveGeneralSuccessMessage: "",
      });
    }
  };

  openRejectionAlert = (id, tableMeta) => {
    this.setState({
      general_submission_id: id,
      rejectGeneralErrorMessage: "",
      rejectGeneralSuccessMessage: "",
      reject_general_reason: "",
      reject_general_button: false,
    });
    console.log("resendForm: ", id);
    window.$("#modalRejectionAlert").modal("show");
  };

  reject_general_form = async () => {
    const {
      general_submission_id,
      reject_general_reason,
      form_accountno,
      form_title,
    } = this.state;
    this.setState({
      reject_general_button: true,
      rejectGeneralErrorMessage: "",
      rejectGeneralSuccessMessage: "",
    });
    let is_validated = true;
    if (reject_general_reason === "") {
      is_validated = false;
    }
    if (is_validated === true) {
      const servicesResponse = await rejectGeneralSubmission(
        auth.getAccount(),
        auth.getToken(),
        form_accountno,
        general_submission_id,
        reject_general_reason,
        form_title
      );

      console.log("rejectGeneralSubmission: ", servicesResponse.data);

      if (
        servicesResponse.data.status === 403 ||
        servicesResponse.data.errors === "authentication missing" ||
        servicesResponse.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        servicesResponse.data.status === 400 &&
        servicesResponse.data.message === "form_not_found"
      ) {
        this.setState({
          rejectGeneralErrorMessage:
            "There is some error while rejecting the submission.",
          rejectGeneralSuccessMessage: "",
          reject_general_button: false,
        });
      } else if (
        servicesResponse.data.status === 200 &&
        servicesResponse.data.message === "success"
      ) {
        this.setState(
          {
            rejectGeneralErrorMessage: "",
            rejectGeneralSuccessMessage: "Submission rejected successfully.",
            reject_general_button: false,
            reject_general_reason: "",
          },
          () => {
            setTimeout(() => {
              this.getTotalSubmissions();
              window.$("#modalRejectionAlert").modal("hide");
              this.setState({
                rejectGeneralErrorMessage: "",
                rejectGeneralSuccessMessage:
                  "Submission rejected successfully.",
              });
              //window.location.reload();
            }, 3000);
          }
        );
      } else {
        this.setState({
          reject_general_button: false,
          rejectGeneralErrorMessage:
            "There is some error while rejecting the submission.",
          rejectGeneralSuccessMessage: "",
        });
      }
    } else {
      this.setState({
        reject_general_button: false,
        rejectGeneralErrorMessage: "Please enter the reason.",
        rejectGeneralSuccessMessage: "",
      });
    }
  };

  /****### CLIENT/AGENT ACTIONS ###****/
  openApproveClientAlert = (id, type, tableMeta) => {
    this.setState({
      client_submission_id: id,
      client_submission_type: type,
      approveClientErrorMessage: "",
      approveClientSuccessMessage: "",
      approve_client_button: false,
    });
    window.$("#modalApproveClientAlert").modal("show");
  };

  approve_client_form = async () => {
    const {
      client_submission_id,
      client_submission_type,
      form_accountno,
      form_title,
    } = this.state;
    this.setState({
      approveClientErrorMessage: "",
      approveClientSuccessMessage: "",
      approve_client_button: true,
    });

    const servicesResponse = await approveClientSubmission(
      auth.getAccount(),
      auth.getToken(),
      form_accountno,
      client_submission_id,
      form_title,
      client_submission_type
    );

    console.log("approveClientSubmission: ", servicesResponse.data);

    if (
      servicesResponse.data.status === 403 ||
      servicesResponse.data.errors === "authentication missing" ||
      servicesResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponse.data.status === 400 &&
      servicesResponse.data.message === "form_not_found"
    ) {
      this.setState({
        approveClientErrorMessage:
          "There is some error while approving the submission.",
        approveClientSuccessMessage: "",
        approve_client_button: false,
      });
    } else if (
      servicesResponse.data.status === 200 &&
      servicesResponse.data.message === "success"
    ) {
      this.setState(
        {
          approveClientErrorMessage: "",
          approveClientSuccessMessage: "Submission approved successfully.",

          reject_general_reason: "",
        },
        () => {
          setTimeout(() => {
            if (client_submission_type === "clients") {
              this.getClientSubmissions();
            }
            if (client_submission_type === "agents") {
              this.getAgentSubmissions();
            }
            window.$("#modalApproveClientAlert").modal("hide");
            this.setState({
              approveClientErrorMessage: "",
              approveClientSuccessMessage: "",
            });
            //window.location.reload();
          }, 3000);
        }
      );
    } else {
      this.setState({
        approve_client_button: false,
        approveClientErrorMessage:
          "There is some error while approving the submission.",
        approveClientSuccessMessage: "",
      });
    }
  };

  openRejectionClientAlert = (id, type, tableMeta) => {
    let rejection_client_title = "Reject Submission";
    if (type === "clients") {
      rejection_client_title = "Reject Client Submission";
    }
    if (type === "agents") {
      rejection_client_title = "Reject Channel Partner Submission";
    }
    this.setState({
      client_submission_id: id,
      rejectClientErrorMessage: "",
      rejectClientSuccessMessage: "",
      reject_client_reason: "",
      reject_client_button: false,
      rejection_client_title: rejection_client_title,
      client_submission_type: type,
    });
    console.log("resendForm: ", id);
    window.$("#modalRejectionClientAlert").modal("show");
  };

  reject_client_form = async () => {
    const {
      client_submission_id,
      reject_client_reason,
      form_accountno,
      form_title,
      client_submission_type,
    } = this.state;
    this.setState({
      reject_client_button: true,
      rejectClientErrorMessage: "",
      rejectClientSuccessMessage: "",
    });
    let is_validated = true;
    if (reject_client_reason === "") {
      is_validated = false;
    }
    if (is_validated === true) {
      const servicesResponse = await rejectClientSubmission(
        auth.getAccount(),
        auth.getToken(),
        form_accountno,
        client_submission_id,
        reject_client_reason,
        form_title,
        client_submission_type
      );

      console.log("reject_client_form: ", servicesResponse.data);

      if (
        servicesResponse.data.status === 403 ||
        servicesResponse.data.errors === "authentication missing" ||
        servicesResponse.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        servicesResponse.data.status === 400 &&
        servicesResponse.data.message === "form_not_found"
      ) {
        this.setState({
          rejectClientErrorMessage:
            "There is some error while rejecting the submission.",
          rejectClientSuccessMessage: "",
          reject_client_button: false,
        });
      } else if (
        servicesResponse.data.status === 200 &&
        servicesResponse.data.message === "success"
      ) {
        this.setState(
          {
            rejectClientErrorMessage: "",
            rejectClientSuccessMessage: "Submission rejected successfully.",
            reject_client_button: false,
            reject_general_reason: "",
          },
          () => {
            setTimeout(() => {
              if (client_submission_type === "clients") {
                this.getClientSubmissions();
              }
              if (client_submission_type === "agents") {
                this.getAgentSubmissions();
              }
              window.$("#modalRejectionClientAlert").modal("hide");
              this.setState({
                rejectClientErrorMessage: "",
                rejectClientSuccessMessage: "Submission rejected successfully.",
              });
              //window.location.reload();
            }, 3000);
          }
        );
      } else {
        this.setState({
          reject_client_button: false,
          rejectClientErrorMessage:
            "There is some error while rejecting the submission.",
          rejectClientSuccessMessage: "",
        });
      }
    } else {
      this.setState({
        reject_client_button: false,
        rejectClientErrorMessage: "Please enter the reason.",
        rejectClientSuccessMessage: "",
      });
    }
  };

  modalAllRejectionsClient = async (id, type, tableMeta) => {
    const { form_accountno, form_title } = this.state;
    let rejection_client_title = "All Rejections";
    if (type === "clients") {
      rejection_client_title = "Client Rejections";
    }
    if (type === "agents") {
      rejection_client_title = "Channel Partner Rejections";
    }
    this.setState({
      client_submission_id: id,
      rejectClientErrorMessage: "",
      rejectClientSuccessMessage: "",
      reject_client_reason: "",
      reject_client_button: false,
      rejection_client_title: rejection_client_title,
      client_submission_type: type,
    });
    this.setState({
      rejectGeneralErrorMessage: "",
      rejectGeneralSuccessMessage: "",
      allClientRejectionsListing: [],
      rejectionLoader: true,
    });
    window.$("#modalRejectionClients").modal("show");
    const servicesResponse = await allClientRejections(
      auth.getAccount(),
      auth.getToken(),
      form_accountno,
      id,
      type
    );

    console.log("allClientRejections: ", servicesResponse.data);

    if (
      servicesResponse.data.status === 403 ||
      servicesResponse.data.errors === "authentication missing" ||
      servicesResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponse.data.status === 404 &&
      servicesResponse.data.message === "form_not_found"
    ) {
      this.setState({
        approveGeneralErrorMessage: "Submission not found.",
        approveGeneralSuccessMessage: "",
        rejectionLoader: false,
      });
    } else if (
      servicesResponse.data.status === 404 &&
      servicesResponse.data.message === "rejection_not_found"
    ) {
      this.setState({
        approveGeneralErrorMessage:
          "There are no rejections against this submission.",
        approveGeneralSuccessMessage: "",
        rejectionLoader: false,
      });
    } else if (
      servicesResponse.data.status === 200 &&
      servicesResponse.data.message === "success"
    ) {
      this.setState({
        approveGeneralErrorMessage: "",
        approveGeneralSuccessMessage: "",
        allClientRejectionsListing: servicesResponse.data.data,
        rejectionLoader: false,
      });
    } else {
      this.setState({
        rejectionLoader: false,
        approveGeneralErrorMessage:
          "There is some error while getting the rejections.",
        approveGeneralSuccessMessage: "",
      });
    }
  };

  closeModal = (modalId) => {
    window.$("#" + modalId).modal("hide");
  };

  viewClientSubmission = async (id, tableMeta) => {
    const { form_accountno } = this.state;
    this.setState({ viewLoader: true });
    const servicesResponse = await viewClientSubmission(
      auth.getAccount(),
      auth.getToken(),
      form_accountno,
      id
    );

    window.$("#modalClientSubmission").modal("show");
    console.log("viewClientSubmission: ", servicesResponse.data);

    if (
      servicesResponse.data.status === 403 ||
      servicesResponse.data.errors === "authentication missing" ||
      servicesResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponse.data.status === 404 &&
      servicesResponse.data.message === "form_not_found"
    ) {
      this.setState({
        errorMessageTotalsTitle: "No Submission",
        errorMessageTotals: "There are no submissions right now.",
        viewLoader: false,
      });
    } else if (
      servicesResponse.data.status === 200 &&
      servicesResponse.data.message === "success"
    ) {
      let extra_files = [];
      if (servicesResponse.data.data && servicesResponse.data.data.length > 0) {
        if (servicesResponse.data.data[0].extra_files) {
          extra_files = JSON.parse(servicesResponse.data.data[0].extra_files);
        }
      }
      this.setState({
        viewClientSubmissions: servicesResponse.data.data,
        extra_files_clients: extra_files,
        viewLoader: false,
      });

      //
    }
  };

  viewAgentSubmission = async (id, tableMeta) => {
    const { form_accountno } = this.state;
    this.setState({ viewLoader: true });
    const servicesResponse = await viewAgentSubmission(
      auth.getAccount(),
      auth.getToken(),
      form_accountno,
      id
    );

    window.$("#modalAgentSubmission").modal("show");
    console.log("viewAgentSubmission: ", servicesResponse.data);

    if (
      servicesResponse.data.status === 403 ||
      servicesResponse.data.errors === "authentication missing" ||
      servicesResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponse.data.status === 404 &&
      servicesResponse.data.message === "form_not_found"
    ) {
      this.setState({
        errorMessageTotalsTitle: "No Submission",
        errorMessageTotals: "There are no submissions right now.",
        viewLoader: false,
      });
    } else if (
      servicesResponse.data.status === 200 &&
      servicesResponse.data.message === "success"
    ) {
      let extra_files = [];
      if (servicesResponse.data.data && servicesResponse.data.data.length > 0) {
        if (servicesResponse.data.data[0].extra_files) {
          extra_files = JSON.parse(servicesResponse.data.data[0].extra_files);
        }
      }
      this.setState({
        viewAgentSubmissions: servicesResponse.data.data,
        extra_files_agents: extra_files,
        viewLoader: false,
      });

      //
    }
  };

  deleteGeneralModal = async (id) => {
    this.setState({ submission_id: id });
    window.$("#modalDeleteGeneralAlert").modal("show");
  };

  modalHideDelGeneral = async () => {
    this.setState({ submission_id: "" });
    window.$("#modalDeleteGeneralAlert").modal("hide");
  };

  modalHideAlll = async (id) => {
    window.$("#" + id).modal("hide");
  };

  deleteGeneralSubmission = async (id) => {
    const { form_accountno, submission_id } = this.state;
    this.setState({ viewLoader: true });
    const servicesResponse = await deleteGeneralSubmission(
      auth.getAccount(),
      auth.getToken(),
      form_accountno,
      submission_id
    );

    console.log("getTotalSubmissions.data.data: ", servicesResponse.data);

    if (
      servicesResponse.data.status === 403 ||
      servicesResponse.data.errors === "authentication missing" ||
      servicesResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponse.data.status === 404 &&
      servicesResponse.data.message === "form_not_found"
    ) {
      this.setState({
        errorMessageDelete: "There are no submissions right now.",
        isProcessing: false,
      });
    } else if (
      servicesResponse.data.status === 200 &&
      servicesResponse.data.message === "success"
    ) {
      this.setState(
        {
          successMessageDelte: "Submission deleted successfully.",
          isProcessing: false,
        },
        () => {
          setTimeout(() => {
            this.getTotalSubmissions();
            window.$("#modalDeleteGeneralAlert").modal("hide");
            this.setState({ successMessage: "" });
            //window.location.reload();
          }, 3000);
        }
      );
    } else {
      this.setState({
        errorMessageDelete: "Error failed to delete submission.",
        isProcessing: false,
      });
    }
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  validateEmailAdd = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email);
    //console.log("isValidEmail: " + " " + email + " = " + isValidEmail);
    if (isValidEmail) {
      return true;
    }
    return false;
  };

  fieldValidation = () => {
    let { emailtoname, emailto, emailsubject, emailcontent } = this.state;

    if (emailtoname === "") {
      this.setState({
        emailErrorMessageTitle: "Missing Fields",
        emailErrorMessage: "Name is required.",
      });
      return false;
    } else if (emailto === "") {
      this.setState({
        emailErrorMessageTitle: "Missing Fields",
        emailErrorMessage: "To email address is required.",
      });
      return false;
    } else if (this.validateEmailAdd(emailto) === false) {
      this.setState({
        emailErrorMessageTitle: "Invalid Format",
        emailErrorMessage: "Invalid email address format.",
      });
      return false;
    } else if (emailsubject === "") {
      this.setState({
        emailErrorMessageTitle: "Missing Fields",
        emailErrorMessage: "Subject is required.",
      });
      return false;
    } else if (emailcontent === "") {
      this.setState({
        emailErrorMessageTitle: "Missing Fields",
        emailErrorMessage: "Custom content is required.",
      });
      return false;
    } else {
      this.setState({
        emailErrorMessageTitle: "",
        emailErrorMessage: "",
      });
    }
    return true;
  };

  submitFormEmail = async () => {
    const {
      form_accountno,
      emailtoname,
      emailto,
      emailsubject,
      emailcontent,
      select_agents,
      selected_emails,
    } = this.state;

    if (this.fieldValidation() === true) {
      this.setState({
        email_button: true,
        emailErrorMessage: "",
        emailErrorMessageTitle: "",
        emailSuccessMessage: "",
      });
      const servicesResponce = await sendFormEmail(
        auth.getAccount(),
        auth.getToken(),
        form_accountno,
        emailtoname,
        emailto,
        emailsubject,
        emailcontent,
        select_agents,
        selected_emails
      );
      console.log("submitOrderEmail: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        servicesResponce.data.status === 404 &&
        servicesResponce.data.message === "already_sent"
      ) {
        this.setState({
          emailErrorMessageTitle: "Already Sent",
          emailErrorMessage:
            "This form has already been sent to the email and is still not submitted.",
          email_button: false,
        });
      } else if (
        servicesResponce.data.status === 404 &&
        servicesResponce.data.message === "insert_error"
      ) {
        this.setState({
          emailErrorMessageTitle: "System Error",
          emailErrorMessage: "This is some error while sending the form.",
          email_button: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          emailSuccessMessage:
            "Email sent successfully. Please wait, page will refresh automatically.",
          payment_status: servicesResponce.data.data,
          emailErrorMessageTitle: "",
          emailErrorMessage: "",
          emailsubject: "",
          emailto: "",
          emailtoname: "",
          emailcontent: "",
          email_button: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        this.setState({
          emailErrorMessageTitle: "System Error",
          emailErrorMessage:
            "There is some error while processing your request.",
          email_button: false,
        });
      }
    }

    setTimeout(() => {
      this.setState({
        emailErrorMessage: "",
        emailSuccessMessage: "",
      });
    }, 4000);
  };

  downloadPdf = async (d_f_name) => {
    //var url = d_f_name;
    if (d_f_name && d_f_name !== "") {
      let file_name = d_f_name;
      var url =
        AGENT_PORTAL_URL + "files_data/form_response_uploads/" + d_f_name;
      fetch(url)
        .then((x) => x.blob())
        .then((b) => {
          // console.log("B INSTANCE", b instanceof Blob);
          const url = window.URL.createObjectURL(b);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = file_name;
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          this.setState({
            errorMessage:
              "There is some error while downloading the attachment.",
          });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 4000);
          // Handle any errors that occurred during the fetch
          console.error("Fetch error:", error);
        });
    } else {
      this.setState({
        errorMessage: "Attachement not found.",
      });
      setTimeout(() => {
        this.setState({ errorMessage: "" });
      }, 4000);
    }
  };

  downloadPdfGeneral = async (d_f_name) => {
    //var url = d_f_name;
    if (d_f_name && d_f_name !== "") {
      let file_name = d_f_name;
      var url = APP_LIVE_URL + "files_data/form_uploads/" + d_f_name;
      fetch(url)
        .then((x) => x.blob())
        .then((b) => {
          // console.log("B INSTANCE", b instanceof Blob);
          const url = window.URL.createObjectURL(b);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = file_name;
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          this.setState({
            errorMessage:
              "There is some error while downloading the attachment.",
          });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 4000);
          // Handle any errors that occurred during the fetch
          console.error("Fetch error:", error);
        });
    } else {
      this.setState({
        errorMessage: "Attachement not found.",
      });
      setTimeout(() => {
        this.setState({ errorMessage: "" });
      }, 4000);
    }
  };

  downloadFormPdf = async (d_f_name) => {
    //var url = d_f_name;
    if (d_f_name && d_f_name !== "") {
      let file_name = d_f_name;
      var url =
        AGENT_PORTAL_URL + "files_data/form_response_uploads/" + d_f_name;
      fetch(url)
        .then((x) => x.blob())
        .then((b) => {
          // console.log("B INSTANCE", b instanceof Blob);
          const url = window.URL.createObjectURL(b);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = file_name;
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          this.setState({
            errorMessage:
              "There is some error while downloading the attachment.",
          });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 4000);
          // Handle any errors that occurred during the fetch
          console.error("Fetch error:", error);
        });
    } else {
      this.setState({
        errorMessage: "Attachement not found.",
      });
      setTimeout(() => {
        this.setState({ errorMessage: "" });
      }, 4000);
    }
  };

  listAllActiveClients = async () => {
    const servicesResponce = await listActiveClients(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("listAllActiveClients: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      //auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        errorMessage: "The question not found.",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let all_clients = [];
      if (
        servicesResponce.data.all_data &&
        servicesResponce.data.all_data.length > 0
      ) {
        for (let i = 0; i < servicesResponce.data.all_data.length; i++) {
          let newArr = {
            label:
              servicesResponce.data.all_data[i]["name"] +
              " (" +
              servicesResponce.data.all_data[i]["comp_name"] +
              ")",
            value:
              servicesResponce.data.all_data[i]["email"] +
              "###" +
              servicesResponce.data.all_data[i]["name"],
          };
          all_clients.push(newArr);
        }
        let newArr2 = {
          label: "Other",
          value: "other",
        };
        all_clients.push(newArr2);
      }
      this.setState({
        all_active_clients: all_clients,
      });
    } else {
      this.setState({
        errorMessage: "",
      });
    }
  };

  listActiveAgents = async () => {
    const servicesResponce = await listAgents(
      auth.getAccount(),
      auth.getToken()
    );

    console.log("listActiveAgents: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        formLoader: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let all_clients = [];
      if (servicesResponce.data.data && servicesResponce.data.data.length > 0) {
        for (let i = 0; i < servicesResponce.data.data.length; i++) {
          let newArr = {
            label:
              servicesResponce.data.data[i][1] +
              " (" +
              servicesResponce.data.data[i][2] +
              ")",
            value:
              servicesResponce.data.data[i][3] +
              "###" +
              servicesResponce.data.data[i][1],
          };
          all_clients.push(newArr);
        }
        let newArr2 = {
          label: "Other",
          value: "other",
        };
        all_clients.push(newArr2);
      }
      this.setState({
        all_active_agents: all_clients,
      });
    }
  };

  handleChangeSearch = async (changed_value) => {
    console.log("handleChangeSearch: ", changed_value);

    if (changed_value) {
      if (changed_value.value === "other") {
        this.setState({
          selected_emails: changed_value.value,
          emailto: "",
          emailtoname: "",
        });
      } else {
        let emails = changed_value.value.split("###");
        this.setState(
          (prevState) => ({
            client_accountno: changed_value.value,
            selected_client_accountno: changed_value,
            selected_emails: "",
            emailto: emails[0],
            emailtoname: emails[1],
          }),
          () => {
            // This callback function is called right after the state is updated
            console.log("Updated state:", this.state.client_accountno);
          }
        );
      }
    } else {
      this.setState(
        {
          client_accountno: null,
          selected_client_accountno: null,
          emailto: "",
          emailtoname: "",
          selected_emails: "",
        },
        () => {
          console.log("Cleared selection");
        }
      );
    }
  };

  handleChangeSearchAgents = async (changed_value) => {
    console.log("handleChangeSearch: ", changed_value);

    if (changed_value) {
      if (changed_value.value === "other") {
        this.setState({
          selected_emails: changed_value.value,
          emailto: "",
          emailtoname: "",
        });
      } else {
        let emails = changed_value.value.split("###");
        this.setState(
          (prevState) => ({
            agent_accountno: changed_value.value,
            selected_agent_accountno: changed_value,
            selected_emails: "",
            emailtoname: emails[1],
            emailto: emails[0],
          }),
          () => {
            // This callback function is called right after the state is updated
            console.log("Updated state:", this.state.agent_accountno);
          }
        );
      }
    } else {
      this.setState(
        {
          agent_accountno: null,
          selected_agent_accountno: null,
          emailto: "",
          emailtoname: "",
          selected_emails: "",
        },
        () => {
          console.log("Cleared selection");
        }
      );
    }
  };

  handleChangeSelectAgents = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
      emailto: "",
    });

    //console.log("handleChangeCheckbox:", name + " " + value);
  };

  render() {
    return (
      <div class="nk-content ">
        <div class="container-fluid">
          <div class="nk-content-inner">
            <div class="nk-content-body">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between g-3">
                  <div class="nk-block-head-content">
                    <h3 class="nk-block-title page-title">
                      Forms /{" "}
                      <strong class="text-primary small">
                        {this.state.form_title}
                      </strong>
                    </h3>
                    <div class="nk-block-des text-soft">
                      <ul class="list-inline">
                        <li>
                          Form ID:{" "}
                          <span class="text-base">
                            {this.state.form_accountno}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <a
                      onClick={() => this.addMenuHistory("/forms/")}
                      href="#"
                      class="btn btn-outline-light bg-white d-none d-sm-inline-flex"
                    >
                      <em class="icon ni ni-arrow-left"></em>
                      <span>Back</span>
                    </a>
                    <a
                      onClick={() => this.addMenuHistory("/forms/")}
                      href="#"
                      class="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
                    >
                      <em class="icon ni ni-arrow-left"></em>
                    </a>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="card card-bordered">
                  <div class="card-aside-wrap">
                    <div class="card-content">
                      <ul class="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                        <li class="nav-item">
                          <a
                            className={`nav-link ${
                              this.state.activeTab === "total_submissions"
                                ? "active"
                                : ""
                            }`}
                            onClick={(e) => {
                              this.changeActiveTab("total_submissions");
                              this.getTotalSubmissions();
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <em class="icon ni ni-file-text"></em>
                            <span>General Submissions</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            className={`nav-link ${
                              this.state.activeTab === "client_submissions"
                                ? "active"
                                : ""
                            }`}
                            onClick={(e) => {
                              this.changeActiveTab("client_submissions");
                              this.getClientSubmissions();
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <em class="icon ni ni-file-text"></em>
                            <span>Client Submissions</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            className={`nav-link ${
                              this.state.activeTab === "agent_submissions"
                                ? "active"
                                : ""
                            }`}
                            onClick={(e) => {
                              this.changeActiveTab("agent_submissions");
                              this.getAgentSubmissions();
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <em class="icon ni ni-file-text"></em>
                            <span>Channel Partner Submissions</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            className={`nav-link ${
                              this.state.activeTab === "email_form"
                                ? "active"
                                : ""
                            }`}
                            onClick={(e) => {
                              this.changeActiveTab("email_form");
                              this.listAllActiveClients();
                              this.listActiveAgents();
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <em class="icon ni ni-mail"></em>
                            <span>Email Form</span>
                          </a>
                        </li>
                      </ul>
                      <div class="tab-content">
                        <div
                          className={
                            this.state.activeTab === "email_form"
                              ? "tab-pane active"
                              : "tab-pane"
                          }
                          id="email_forms"
                        >
                          <div class="card-inner">
                            <div class="nk-block">
                              <div class="row g-gs">
                                <div class="col-sm-12">
                                  <h5>Send Form</h5>
                                  {this.state.emailErrorMessage !== "" ? (
                                    <div class="alert alert-pro alert-danger">
                                      <div class="alert-text">
                                        <h6>
                                          {this.state.emailErrorMessageTitle}
                                        </h6>
                                        <p>{this.state.emailErrorMessage}</p>
                                      </div>
                                    </div>
                                  ) : null}
                                  {this.state.emailSuccessMessage !== "" ? (
                                    <div class="alert alert-pro alert-success">
                                      <div class="alert-text">
                                        <h6>Success</h6>
                                        <p>{this.state.emailSuccessMessage}</p>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <ul className="nk-kycfm-control-list g-3">
                                      <li className="nk-kycfm-control-item">
                                        <input
                                          checked={
                                            this.state.select_agents ===
                                            "general"
                                              ? true
                                              : false
                                          }
                                          onChange={
                                            this.handleChangeSelectAgents
                                          }
                                          className="nk-kycfm-control"
                                          type="radio"
                                          name="select_agents"
                                          id="select_agents2"
                                          data-title="Select Client"
                                          value="general"
                                        />
                                        <label
                                          className="nk-kycfm-label"
                                          htmlFor="select_agents2"
                                        >
                                          <span className="nk-kycfm-label-icon">
                                            <div className="label-icon ">
                                              <em
                                                class="icon ni ni-user-list fs-20"
                                                style={{ fontSize: "20px" }}
                                              ></em>
                                            </div>
                                          </span>
                                          <span className="nk-kycfm-label-text">
                                            General
                                          </span>
                                        </label>
                                      </li>
                                      <li className="nk-kycfm-control-item">
                                        <input
                                          checked={
                                            this.state.select_agents ===
                                            "clients"
                                              ? true
                                              : false
                                          }
                                          onChange={
                                            this.handleChangeSelectAgents
                                          }
                                          className="nk-kycfm-control"
                                          type="radio"
                                          name="select_agents"
                                          id="select_agents1"
                                          data-title="Select Client"
                                          value="clients"
                                        />
                                        <label
                                          className="nk-kycfm-label"
                                          htmlFor="select_agents1"
                                        >
                                          <span className="nk-kycfm-label-icon">
                                            <div className="label-icon ">
                                              <em
                                                class="icon ni ni-user-list fs-20"
                                                style={{ fontSize: "20px" }}
                                              ></em>
                                            </div>
                                          </span>
                                          <span className="nk-kycfm-label-text">
                                            Select Client
                                          </span>
                                        </label>
                                      </li>
                                      <li className="nk-kycfm-control-item">
                                        <input
                                          checked={
                                            this.state.select_agents ===
                                            "agents"
                                              ? true
                                              : false
                                          }
                                          onChange={
                                            this.handleChangeSelectAgents
                                          }
                                          className="nk-kycfm-control"
                                          type="radio"
                                          name="select_agents"
                                          id="select_agents"
                                          data-title="Select Agents"
                                          value="agents"
                                        />
                                        <label
                                          className="nk-kycfm-label"
                                          htmlFor="select_agents"
                                        >
                                          <span className="nk-kycfm-label-icon">
                                            <div className="label-icon">
                                              <em
                                                class="icon ni ni-users"
                                                style={{ fontSize: "20px" }}
                                              ></em>
                                            </div>
                                          </span>
                                          <span className="nk-kycfm-label-text">
                                            Select Channel Partner
                                          </span>
                                        </label>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                {this.state.select_agents === "clients" ? (
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <div className="form-label-group">
                                        <label className="form-label">
                                          Choose Client{" "}
                                          <span className="text-danger"></span>
                                        </label>
                                      </div>
                                      <div className="form-control-group">
                                        <Select
                                          options={
                                            this.state.all_active_clients
                                          }
                                          name="selected_client_accountno"
                                          placeholder="Select a client to send the form."
                                          value={
                                            this.state.selected_client_accountno
                                          }
                                          autoComplete="off"
                                          emptyMessage="Clients not found"
                                          onChange={this.handleChangeSearch}
                                          isClearable
                                          isSearchable
                                          components={animatedComponents}
                                          styles={{
                                            control: (baseStyles, state) => ({
                                              ...baseStyles,
                                              lineHeight: "30px",
                                            }),
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ) : null}

                                {this.state.select_agents === "agents" ? (
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <div className="form-label-group">
                                        <label className="form-label">
                                          Choose Channel Partner{" "}
                                          <span className="text-danger"></span>
                                        </label>
                                      </div>
                                      <div className="form-control-group">
                                        <Select
                                          options={this.state.all_active_agents}
                                          name="selected_agent_accountno"
                                          placeholder="Select a channel partner to send the form."
                                          value={
                                            this.state.selected_agent_accountno
                                          }
                                          autoComplete="off"
                                          emptyMessage="Channel partners not found"
                                          onChange={
                                            this.handleChangeSearchAgents
                                          }
                                          isClearable
                                          isSearchable
                                          components={animatedComponents}
                                          styles={{
                                            control: (baseStyles, state) => ({
                                              ...baseStyles,
                                              lineHeight: "30px",
                                            }),
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                <div class="row col-md-12">
                                  <div class="col-sm-6">
                                    <div class="form-group mt-2">
                                      <label class="form-label" for="emailto">
                                        To Name <sup>*</sup>
                                      </label>
                                      <div class="form-control-wrap">
                                        <input
                                          type="text"
                                          class="form-control form-control-lg"
                                          id="emailtoname"
                                          name="emailtoname"
                                          value={this.state.emailtoname}
                                          onChange={this.handleChange}
                                          placeholder="Enter the full name"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-sm-6">
                                    <div class="form-group mt-2">
                                      <label class="form-label" for="emailto">
                                        To <sup>*</sup>
                                      </label>
                                      <div class="form-control-wrap">
                                        <input
                                          type="text"
                                          class="form-control form-control-lg"
                                          id="emailto"
                                          name="emailto"
                                          value={this.state.emailto}
                                          onChange={this.handleChange}
                                          placeholder="Enter email address"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-sm-12 mt-2">
                                    <div class="form-group">
                                      <label
                                        class="form-label"
                                        for="emailsubject"
                                      >
                                        Subject <sup>*</sup>
                                      </label>
                                      <div class="form-control-wrap">
                                        <input
                                          type="text"
                                          class="form-control form-control-lg"
                                          id="emailsubject"
                                          name="emailsubject"
                                          value={this.state.emailsubject}
                                          onChange={this.handleChange}
                                          placeholder="Email Subject"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-sm-12 mt-2">
                                    <div class="form-group">
                                      <label
                                        class="form-label"
                                        for="emailcontent"
                                      >
                                        Custom Message <sup>*</sup>
                                      </label>
                                      <div class="form-control-wrap">
                                        <textarea
                                          class="form-control"
                                          id="emailcontent"
                                          name="emailcontent"
                                          value={this.state.emailcontent}
                                          onChange={this.handleChange}
                                        ></textarea>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-sm-12 mt-2 d-flex justify-content-end">
                                  <div class="nk-block-head-content">
                                    <ul class="nk-block-tools gx-3">
                                      {this.state.email_button === true ? (
                                        <button
                                          className="btn btn-primary"
                                          type="button"
                                          disabled
                                        >
                                          <span
                                            className="spinner-grow spinner-grow-sm"
                                            role="status"
                                            aria-hidden="true"
                                          ></span>
                                          <span> Processing... </span>
                                        </button>
                                      ) : (
                                        <li>
                                          <a
                                            onClick={() =>
                                              this.submitFormEmail()
                                            }
                                            style={{
                                              cursor: "pointer",
                                              color: "#fff",
                                            }}
                                            class="btn btn-primary"
                                          >
                                            <em class="icon ni ni-check-round-cut"></em>{" "}
                                            <span>Send Email</span>{" "}
                                          </a>
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* EMAIL FORM */}
                        <div
                          className={
                            this.state.activeTab === "total_submissions"
                              ? "tab-pane active"
                              : "tab-pane"
                          }
                          id="tabItemAgreements"
                        >
                          <div class="card-inner">
                            <div class="nk-block">
                              <div class="nk-block-head">
                                <h5 class="title mb-4">General Submissions</h5>

                                {this.state.assignedLoader === true ? (
                                  FormLoader()
                                ) : (
                                  <>
                                    <div
                                      className="tab-pane active"
                                      id="tabItemSigned"
                                    >
                                      <div className="card card-bordered card-preview">
                                        <DataTableExtended
                                          columns={
                                            this.state.generalColumnServices
                                          }
                                          tableData={
                                            this.state.totalSubmissions
                                          }
                                          title=""
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* EMAIL FORM */}
                        {/* CLIENT SUBMISSIONS */}
                        <div
                          className={
                            this.state.activeTab === "client_submissions"
                              ? "tab-pane active"
                              : "tab-pane"
                          }
                          id="tabItemAgreements2"
                        >
                          <div class="card-inner">
                            <div class="nk-block">
                              <div class="nk-block-head">
                                <h5 class="title mb-4">Client Submissions</h5>

                                {this.state.assignedLoader === true ? (
                                  FormLoader()
                                ) : (
                                  <>
                                    <div
                                      className="tab-pane active"
                                      id="tabItemSigned2"
                                    >
                                      <div className="card card-bordered card-preview">
                                        <DataTableExtended
                                          columns={
                                            this.state.clientColumnServices
                                          }
                                          tableData={
                                            this.state.totalClientSubmissions
                                          }
                                          title=""
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* CLIENT SUBMISSIONS */}
                        {/* AGENT SUBMISSIONS */}
                        <div
                          className={
                            this.state.activeTab === "agent_submissions"
                              ? "tab-pane active"
                              : "tab-pane"
                          }
                          id="tabItemAgreements3"
                        >
                          <div class="card-inner">
                            <div class="nk-block">
                              <div class="nk-block-head">
                                <h5 class="title mb-4">
                                  Channel Partner Submissions
                                </h5>

                                {this.state.assignedLoader === true ? (
                                  FormLoader()
                                ) : (
                                  <>
                                    <div
                                      className="tab-pane active"
                                      id="tabItemSigned3"
                                    >
                                      <div className="card card-bordered card-preview">
                                        <DataTableExtended
                                          columns={
                                            this.state.agentColumnServices
                                          }
                                          tableData={
                                            this.state.agentSubmissions
                                          }
                                          title=""
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* AGENT SUBMISSIONS */}
                        <div class="nk-divider divider md"></div>
                      </div>
                    </div>
                    <div
                      class="card-aside card-aside-right user-aside"
                      data-content="userAside"
                      data-toggle-screen="xxl"
                      data-toggle-overlay="true"
                      data-toggle-body="true"
                    >
                      <div class="card-inner-group" data-simplebar>
                        <div class="card-inner">
                          <div class="user-card user-card-s2">
                            <div class="user-info">
                              {this.state.form_status &&
                              this.state.form_status === 1 ? (
                                <div class="badge badge-outline-success badge-pill ucap">
                                  Active
                                </div>
                              ) : (
                                <div class="badge badge-outline-light badge-pill ucap">
                                  Inactive
                                </div>
                              )}

                              <h5>{this.state.form_title}</h5>
                              <span class="sub-text"></span>
                            </div>
                          </div>
                        </div>

                        <div class="card-inner">
                          <h6 class="overline-title-alt mb-2">
                            Completed / Pending
                          </h6>
                          <div class="row text-center">
                            <div class="col-4">
                              <div class="profile-stats">
                                <span class="amount">
                                  {this.state.total_general_completed} /{" "}
                                  {this.state.total_general_submissions}
                                </span>
                                <span class="sub-text">General</span>
                              </div>
                            </div>
                            <div class="col-4">
                              <div class="profile-stats">
                                <span class="amount">
                                  {this.state.total_client_completed} /{" "}
                                  {this.state.total_client_submissions}
                                </span>
                                <span class="sub-text">Client</span>
                              </div>
                            </div>
                            <div class="col-4">
                              <div class="profile-stats">
                                <span class="amount">
                                  {this.state.total_agent_completed} /{" "}
                                  {this.state.total_agent_submissions}
                                </span>
                                <span class="sub-text">CP</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*<div class="card-inner">
                          <h6 class="overline-title-alt mb-2">Additional</h6>
                          <div class="row g-3">
                            <div class="col-6">
                              <span class="sub-text">User ID:</span>
                              <span>UD003054</span>
                            </div>
                            <div class="col-6">
                              <span class="sub-text">Last Login:</span>
                              <span>15 Feb, 2019 01:02 PM</span>
                            </div>
                            <div class="col-6">
                              <span class="sub-text">KYC Status:</span>
                              <span class="lead-text text-success">
                                Approved
                              </span>
                            </div>
                            <div class="col-6">
                              <span class="sub-text">Register At:</span>
                              <span>Nov 24, 2019</span>
                            </div>
                          </div>
                        </div>*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* View General Submissions */}
              <div
                className="modal fade zoom"
                tabIndex="-1"
                id="modalViewGeneralSubmission"
              >
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <div
                        className="modal-title"
                        style={{ textAlign: "center", fontSize: "20px" }}
                      >
                        <strong>
                          <em className="icon ni ni-list-round"></em> Submission
                          Details{" "}
                        </strong>
                      </div>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body">
                      {/* NRC DIVS START */}

                      {this.state.viewLoader === true ? (
                        FormLoader()
                      ) : (
                        <>
                          <div>
                            <div className="card card-bordered">
                              <div className="card-inner-group">
                                {this.state.viewGeneralSubmission &&
                                this.state.viewGeneralSubmission.length > 0 ? (
                                  <div className="card-inner">
                                    <div className="sp-plan-head">
                                      <h6 className="title">
                                        Form Submission Details
                                      </h6>
                                    </div>
                                    <div className="sp-plan-desc sp-plan-desc-mb">
                                      <table className="table table-bordered table-striped">
                                        {this.state.viewGeneralSubmission.map(
                                          (general, index) => (
                                            <tbody
                                              key={index}
                                              className="text-dark"
                                            >
                                              <tr>
                                                <td>Sent To Name</td>
                                                <td>
                                                  {general.submitter_name}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Sent To Email</td>
                                                <td>
                                                  {general.submitter_email}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Sent By</td>
                                                <td>{general.sent_by_email}</td>
                                              </tr>
                                              <tr>
                                                <td>Sent Date</td>
                                                <td>
                                                  {this.format_date(
                                                    general.sent_on
                                                  )}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Submitted</td>
                                                <td>
                                                  {general.is_submitted &&
                                                  general.is_submitted === 1 ? (
                                                    <span class="badge badge-dot badge-success">
                                                      Yes
                                                    </span>
                                                  ) : (
                                                    <span class="badge badge-dot badge-danger">
                                                      No
                                                    </span>
                                                  )}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Submission Date</td>
                                                <td>
                                                  {general.is_submitted &&
                                                  general.is_submitted === 1
                                                    ? this.format_date(
                                                        general.submitted_on
                                                      )
                                                    : "---"}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>View PDF</td>
                                                <td>
                                                  {general.is_submitted &&
                                                  general.is_submitted === 1 ? (
                                                    <a
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        this.downloadPdfGeneral(
                                                          general.pdf_name
                                                        )
                                                      }
                                                    >
                                                      <span class="badge badge-outline-dark">
                                                        View
                                                      </span>
                                                    </a>
                                                  ) : (
                                                    "---"
                                                  )}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Extra Files</td>
                                                <td>
                                                  {this.state.extra_files &&
                                                  this.state.extra_files !==
                                                    "" ? (
                                                    <>
                                                      {this.state.extra_files.map(
                                                        (file, index) => (
                                                          <>
                                                            {file.file_name &&
                                                            file.file_name !==
                                                              "" ? (
                                                              <div
                                                                key={index}
                                                                className="col-md-12"
                                                                style={{
                                                                  paddingLeft:
                                                                    "0px",
                                                                }}
                                                              >
                                                                {file.field_name.trim()}{" "}
                                                                <span
                                                                  onClick={() =>
                                                                    this.downloadPdfGeneral(
                                                                      file.trim()
                                                                    )
                                                                  }
                                                                  class="badge badge-outline-primary"
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                  title="Download / View"
                                                                >
                                                                  <em class="icon ni ni-download"></em>{" "}
                                                                  {file.file_name.trim()}
                                                                </span>
                                                              </div>
                                                            ) : null}
                                                          </>
                                                        )
                                                      )}
                                                    </>
                                                  ) : (
                                                    "---"
                                                  )}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Sent Form Subject</td>
                                                <td>{general.email_subject}</td>
                                              </tr>
                                              <tr>
                                                <td>Sent Form Message</td>
                                                <td>{general.email_content}</td>
                                              </tr>
                                            </tbody>
                                          )
                                        )}
                                      </table>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* View General Submissions */}

              {/* View Client Submissions */}
              <div
                className="modal fade zoom"
                tabIndex="-1"
                id="modalClientSubmission"
              >
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <div
                        className="modal-title"
                        style={{ textAlign: "center", fontSize: "20px" }}
                      >
                        <strong>
                          <em className="icon ni ni-list-round"></em> Submission
                          Details{" "}
                        </strong>
                      </div>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body">
                      {/* NRC DIVS START */}

                      {this.state.viewLoader === true ? (
                        FormLoader()
                      ) : (
                        <>
                          <div>
                            <div className="card card-bordered">
                              <div className="card-inner-group">
                                {this.state.viewClientSubmissions &&
                                this.state.viewClientSubmissions.length > 0 ? (
                                  <div className="card-inner">
                                    <div className="sp-plan-head">
                                      <h6 className="title">
                                        Form Submission Details
                                      </h6>
                                    </div>
                                    <div className="sp-plan-desc sp-plan-desc-mb">
                                      <table className="table table-bordered table-striped">
                                        {this.state.viewClientSubmissions.map(
                                          (general, index) => (
                                            <tbody
                                              key={index}
                                              className="text-dark"
                                            >
                                              <tr>
                                                <td>Sent To Name</td>
                                                <td>
                                                  {general.submitter_name}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Sent To Email</td>
                                                <td>
                                                  {general.submitter_email}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Sent By</td>
                                                <td>{general.sent_by_email}</td>
                                              </tr>
                                              <tr>
                                                <td>Sent Date</td>
                                                <td>
                                                  {this.format_date(
                                                    general.sent_on
                                                  )}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Submitted</td>
                                                <td>
                                                  {general.is_submitted &&
                                                  general.is_submitted === 1 ? (
                                                    <span class="badge badge-dot badge-success">
                                                      Yes
                                                    </span>
                                                  ) : (
                                                    <span class="badge badge-dot badge-danger">
                                                      No
                                                    </span>
                                                  )}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Submission Date</td>
                                                <td>
                                                  {general.is_submitted &&
                                                  general.is_submitted === 1
                                                    ? this.format_date(
                                                        general.submitted_on
                                                      )
                                                    : "---"}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>View PDF</td>
                                                <td>
                                                  {general.is_submitted &&
                                                  general.is_submitted === 1 ? (
                                                    <a
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        this.downloadFormPdf(
                                                          general.pdf_name
                                                        )
                                                      }
                                                    >
                                                      <span class="badge badge-outline-dark">
                                                        View
                                                      </span>
                                                    </a>
                                                  ) : (
                                                    "---"
                                                  )}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Extra Files</td>
                                                <td>
                                                  {this.state
                                                    .extra_files_clients &&
                                                  this.state
                                                    .extra_files_clients !==
                                                    "" ? (
                                                    <>
                                                      {this.state.extra_files_clients.map(
                                                        (file, index) => (
                                                          <>
                                                            {file.file_name &&
                                                            file.file_name !==
                                                              "" ? (
                                                              <div
                                                                key={index}
                                                                className="col-md-12"
                                                                style={{
                                                                  paddingLeft:
                                                                    "0px",
                                                                }}
                                                              >
                                                                {file.field_name.trim()}{" "}
                                                                <span
                                                                  onClick={() =>
                                                                    this.downloadFormPdf(
                                                                      file.file_name.trim()
                                                                    )
                                                                  }
                                                                  class="badge badge-outline-primary"
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                  title="Download / View"
                                                                >
                                                                  <em class="icon ni ni-download"></em>{" "}
                                                                  {file.file_name.trim()}
                                                                </span>
                                                              </div>
                                                            ) : null}
                                                          </>
                                                        )
                                                      )}
                                                    </>
                                                  ) : (
                                                    "---"
                                                  )}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Sent Form Subject</td>
                                                <td>{general.email_subject}</td>
                                              </tr>
                                              <tr>
                                                <td>Sent Form Message</td>
                                                <td>{general.email_content}</td>
                                              </tr>
                                            </tbody>
                                          )
                                        )}
                                      </table>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* View Client Submissions */}

              {/* View Client Submissions */}
              <div
                className="modal fade zoom"
                tabIndex="-1"
                id="modalAgentSubmission"
              >
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <div
                        className="modal-title"
                        style={{ textAlign: "center", fontSize: "20px" }}
                      >
                        <strong>
                          <em className="icon ni ni-list-round"></em> Channel
                          Partner Submission Details{" "}
                        </strong>
                      </div>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body">
                      {/* NRC DIVS START */}

                      {this.state.viewLoader === true ? (
                        FormLoader()
                      ) : (
                        <>
                          <div>
                            <div className="card card-bordered">
                              <div className="card-inner-group">
                                {this.state.viewAgentSubmissions &&
                                this.state.viewAgentSubmissions.length > 0 ? (
                                  <div className="card-inner">
                                    <div className="sp-plan-head">
                                      <h6 className="title">
                                        Form Submission Details
                                      </h6>
                                    </div>
                                    <div className="sp-plan-desc sp-plan-desc-mb">
                                      <table className="table table-bordered table-striped">
                                        {this.state.viewAgentSubmissions.map(
                                          (general, index) => (
                                            <tbody
                                              key={index}
                                              className="text-dark"
                                            >
                                              <tr>
                                                <td>Sent To Name</td>
                                                <td>
                                                  {general.submitter_name}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Sent To Email</td>
                                                <td>
                                                  {general.submitter_email}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Sent By</td>
                                                <td>{general.sent_by_email}</td>
                                              </tr>
                                              <tr>
                                                <td>Sent Date</td>
                                                <td>
                                                  {this.format_date(
                                                    general.sent_on
                                                  )}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Submitted</td>
                                                <td>
                                                  {general.is_submitted &&
                                                  general.is_submitted === 1 ? (
                                                    <span class="badge badge-dot badge-success">
                                                      Yes
                                                    </span>
                                                  ) : (
                                                    <span class="badge badge-dot badge-danger">
                                                      No
                                                    </span>
                                                  )}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Submission Date</td>
                                                <td>
                                                  {general.is_submitted &&
                                                  general.is_submitted === 1
                                                    ? this.format_date(
                                                        general.submitted_on
                                                      )
                                                    : "---"}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>View PDF</td>
                                                <td>
                                                  {general.is_submitted &&
                                                  general.is_submitted === 1 ? (
                                                    <a
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        this.downloadFormPdf(
                                                          general.pdf_name
                                                        )
                                                      }
                                                    >
                                                      <span class="badge badge-outline-dark">
                                                        View
                                                      </span>
                                                    </a>
                                                  ) : (
                                                    "---"
                                                  )}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Extra Files</td>
                                                <td>
                                                  {this.state
                                                    .extra_files_agents &&
                                                  this.state
                                                    .extra_files_agents !==
                                                    "" ? (
                                                    <>
                                                      {this.state.extra_files_agents.map(
                                                        (file, index) => (
                                                          <>
                                                            {file.file_name &&
                                                            file.file_name !==
                                                              "" ? (
                                                              <div
                                                                key={index}
                                                                className="col-md-12"
                                                                style={{
                                                                  paddingLeft:
                                                                    "0px",
                                                                }}
                                                              >
                                                                {file.field_name.trim()}{" "}
                                                                <span
                                                                  onClick={() =>
                                                                    this.downloadFormPdf(
                                                                      file.file_name.trim()
                                                                    )
                                                                  }
                                                                  class="badge badge-outline-primary"
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                  title="Download / View"
                                                                >
                                                                  <em class="icon ni ni-download"></em>{" "}
                                                                  {file.file_name.trim()}
                                                                </span>
                                                              </div>
                                                            ) : null}
                                                          </>
                                                        )
                                                      )}
                                                    </>
                                                  ) : (
                                                    "---"
                                                  )}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Sent Form Subject</td>
                                                <td>{general.email_subject}</td>
                                              </tr>
                                              <tr>
                                                <td>Sent Form Message</td>
                                                <td>{general.email_content}</td>
                                              </tr>
                                            </tbody>
                                          )
                                        )}
                                      </table>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* View Agent Submissions */}
              {/* START REJECTION MODAL */}
              <div class="modal fade" tabindex="-1" id="modalRejectionAlert">
                <div
                  class="modal-dialog modal-dialog-top modal-xl"
                  role="document"
                >
                  <div class="modal-content">
                    <a
                      href="#"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em class="icon ni ni-cross"></em>
                    </a>
                    <div class="modal-header">
                      <h5 class="modal-title">
                        Reject Submission{" "}
                        <sup className="text-primary">
                          {this.state.form_title}
                        </sup>
                      </h5>
                    </div>
                    <div class="modal-body">
                      {this.state.rejectGeneralErrorMessage !== "" ? (
                        <div class="alert alert-pro alert-danger mb-2">
                          <div class="alert-text">
                            <h6>Error</h6>
                            <p>{this.state.rejectGeneralErrorMessage} </p>
                          </div>
                        </div>
                      ) : null}
                      {this.state.rejectGeneralSuccessMessage !== "" ? (
                        <div class="alert alert-pro alert-success mb-2">
                          <div class="alert-text">
                            <h6>Success</h6>
                            <p>{this.state.rejectGeneralSuccessMessage} </p>
                          </div>
                        </div>
                      ) : null}
                      <div id="revokeReasonItems">
                        <div className="tb-odr-id">Reason to reject</div>
                        <div className="card card-bordered card-preview mt-1">
                          <TextareaAutosize
                            maxrows={4}
                            name="reject_general_reason"
                            id="reject_general_reason"
                            aria-label=""
                            placeholder=""
                            value={this.state.reject_general_reason}
                            helperText="Reason to reject this submission"
                            onChange={this.handleChange}
                            style={{
                              width: "100%",
                              height: "100px",
                              borderColor: "rgba(0, 0, 0, 0.125)",
                              borderRadius: "4px",
                            }}
                            variant="outlined"
                          />
                        </div>
                        <div className="form-group text-right mt-2 mb-2">
                          {this.state.reject_general_button === true ? (
                            <button
                              class="btn btn-primary"
                              type="button"
                              disabled
                            >
                              <span
                                class="spinner-grow spinner-grow-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span> Loading... </span>
                            </button>
                          ) : (
                            <>
                              <button
                                type="button"
                                className="btn btn-light"
                                onClick={() => {
                                  this.closeModal("modalRejectionAlert");
                                }}
                                style={{ marginRight: "10px" }}
                              >
                                <em class="icon ni ni-shrink"></em>{" "}
                                <span>Cancel</span>
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => {
                                  this.reject_general_form();
                                }}
                                disabled={this.state.disabled}
                              >
                                <em class="icon ni ni-cross-circle"></em>{" "}
                                <span>Reject</span>
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* END REJECTION MODAL */}
              {/* START APPROVE MODAL  */}
              <div
                className="modal fade"
                tabIndex="-1"
                id="modalApproveGeneralAlert"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <a
                      style={{ cursor: "pointer" }}
                      className="close"
                      onClick={() => {
                        this.modalHideAlll("modalApproveGeneralAlert");
                      }}
                    >
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-body modal-body-lg text-center">
                      <div className="nk-modal">
                        <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-done bg-success"></em>

                        <h4 className="nk-modal-title">Approve Submission?</h4>

                        <div className="nk-modal-text">
                          {this.state.approveGeneralErrorMessage !== "" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.approveGeneralErrorMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.approveGeneralSuccessMessage !== "" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-success">
                                <div className="alert-text">
                                  <h4>Success</h4>
                                  <p>
                                    {this.state.approveGeneralSuccessMessage}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ) : null}

                          <div className="caption-text">
                            Are you sure you want to approve this submission
                          </div>
                        </div>
                        <div className="nk-modal-action">
                          {this.state.approve_general_button ? (
                            <button
                              class="btn btn-lg btn-primary"
                              type="button"
                              disabled
                            >
                              <span
                                class="spinner-grow spinner-grow-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span> Processing... </span>
                            </button>
                          ) : (
                            <>
                              <a
                                style={{ cursor: "pointer" }}
                                className="btn btn-lg btn-dim btn-outline-light"
                                onClick={() => {
                                  this.modalHideAlll(
                                    "modalApproveGeneralAlert"
                                  );
                                }}
                              >
                                <em class="icon ni ni-shrink"></em>{" "}
                                <span>Cancel</span>
                              </a>
                              {"  "}
                              <a
                                style={{ cursor: "pointer" }}
                                className="btn btn-lg btn-mw btn-primary"
                                onClick={() => {
                                  this.approve_general_form();
                                }}
                                disabled={
                                  this.state.disabled || this.state.isProcessing
                                } // disable button when processing
                              >
                                <em class="icon ni ni-done"></em>{" "}
                                <span>Approve</span>
                              </a>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* END APPROVE MODAL  */}
              {/* START REJECTIONS MODAL */}
              <div class="modal fade" tabindex="-1" id="modalRejectionGeneral">
                <div
                  class="modal-dialog modal-dialog-top modal-xl"
                  role="document"
                >
                  <div class="modal-content">
                    <a
                      href="#"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em class="icon ni ni-cross"></em>
                    </a>
                    <div class="modal-header">
                      <h5 class="modal-title">
                        {this.state.rejection_client_title}{" "}
                        <sup className="text-primary">
                          {this.state.form_title}
                        </sup>
                      </h5>
                    </div>
                    <div class="modal-body">
                      {this.state.rejectGeneralErrorMessage !== "" ? (
                        <div class="alert alert-pro alert-danger mb-2">
                          <div class="alert-text">
                            <h6>Error</h6>
                            <p>{this.state.rejectGeneralErrorMessage} </p>
                          </div>
                        </div>
                      ) : null}
                      {this.state.rejectGeneralSuccessMessage !== "" ? (
                        <div class="alert alert-pro alert-success mb-2">
                          <div class="alert-text">
                            <h6>Success</h6>
                            <p>{this.state.rejectGeneralSuccessMessage} </p>
                          </div>
                        </div>
                      ) : null}
                      <div id="revokeReasonItems">
                        {this.state.rejectionLoader === true ? (
                          FormLoader()
                        ) : (
                          <>
                            <div>
                              <div className="card card-bordered">
                                <div className="card-inner-group">
                                  {this.state.allGeneralRejectionsListing &&
                                  this.state.allGeneralRejectionsListing
                                    .length > 0 ? (
                                    <div className="card-inner">
                                      <div className="sp-plan-head">
                                        <h6 className="title">
                                          All Rejections
                                        </h6>
                                      </div>
                                      <div className="sp-plan-desc sp-plan-desc-mb">
                                        <table className="table table-bordered table-striped">
                                          <thead>
                                            <th>Form Title</th>
                                            <th>Rejection date</th>
                                            <th>Reason</th>
                                            <th>Submitted PDF</th>
                                          </thead>
                                          {this.state.allGeneralRejectionsListing.map(
                                            (general, index) => (
                                              <tbody
                                                key={`ind${index}`}
                                                className="text-dark"
                                              >
                                                <tr>
                                                  <td>
                                                    {this.state.form_title}
                                                  </td>
                                                  <td>
                                                    {this.format_date(
                                                      general.rejection_date
                                                    )}
                                                  </td>
                                                  <td>
                                                    {general.rejection_reason}
                                                  </td>
                                                  <td>
                                                    {general.is_submitted &&
                                                    general.is_submitted ===
                                                      1 ? (
                                                      <a
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                          this.downloadPdf(
                                                            general.pdf_name
                                                          )
                                                        }
                                                      >
                                                        <span class="badge badge-outline-dark">
                                                          View
                                                        </span>
                                                      </a>
                                                    ) : (
                                                      "---"
                                                    )}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            )
                                          )}
                                        </table>
                                      </div>
                                    </div>
                                  ) : (
                                    <div class="alert alert-pro alert-primary">
                                      <div class="alert-text">
                                        <h6>No Rejection</h6>
                                        <p>
                                          Rejections are not available against
                                          this submission.
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* END REJECTIONS MODAL */}
              {/* START APPROVE Client MODAL  */}
              <div
                className="modal fade"
                tabIndex="-1"
                id="modalApproveClientAlert"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <a
                      style={{ cursor: "pointer" }}
                      className="close"
                      onClick={() => {
                        this.modalHideAlll("modalApproveClientAlert");
                      }}
                    >
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-body modal-body-lg text-center">
                      <div className="nk-modal">
                        <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-done bg-success"></em>

                        <h4 className="nk-modal-title">Approve Submission?</h4>

                        <div className="nk-modal-text">
                          {this.state.approveClientErrorMessage !== "" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.approveClientErrorMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.approveClientSuccessMessage !== "" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-success">
                                <div className="alert-text">
                                  <h4>Success</h4>
                                  <p>
                                    {this.state.approveClientSuccessMessage}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ) : null}

                          <div className="caption-text">
                            Are you sure you want to approve this submission
                          </div>
                        </div>
                        <div className="nk-modal-action">
                          {this.state.approve_client_button ? (
                            <button
                              class="btn btn-lg btn-primary"
                              type="button"
                              disabled
                            >
                              <span
                                class="spinner-grow spinner-grow-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span> Processing... </span>
                            </button>
                          ) : (
                            <>
                              <a
                                style={{ cursor: "pointer" }}
                                className="btn btn-lg btn-dim btn-outline-light"
                                onClick={() => {
                                  this.modalHideAlll("modalApproveClientAlert");
                                }}
                              >
                                <em class="icon ni ni-shrink"></em>{" "}
                                <span>Cancel</span>
                              </a>
                              {"  "}
                              <a
                                style={{ cursor: "pointer" }}
                                className="btn btn-lg btn-mw btn-primary"
                                onClick={() => {
                                  this.approve_client_form();
                                }}
                                disabled={
                                  this.state.disabled || this.state.isProcessing
                                } // disable button when processing
                              >
                                <em class="icon ni ni-done"></em>{" "}
                                <span>Approve</span>
                              </a>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* END APPROVE CLIENT MODAL  */}
              {/* START REJECTION CLIENT MODAL */}
              <div
                class="modal fade"
                tabindex="-1"
                id="modalRejectionClientAlert"
              >
                <div
                  class="modal-dialog modal-dialog-top modal-xl"
                  role="document"
                >
                  <div class="modal-content">
                    <a
                      href="#"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em class="icon ni ni-cross"></em>
                    </a>
                    <div class="modal-header">
                      <h5 class="modal-title">
                        {this.state.rejection_client_title}{" "}
                        <sup className="text-primary">
                          {this.state.form_title}
                        </sup>
                      </h5>
                    </div>
                    <div class="modal-body">
                      {this.state.rejectClientErrorMessage !== "" ? (
                        <div class="alert alert-pro alert-danger mb-2">
                          <div class="alert-text">
                            <h6>Error</h6>
                            <p>{this.state.rejectClientErrorMessage} </p>
                          </div>
                        </div>
                      ) : null}
                      {this.state.rejectClientSuccessMessage !== "" ? (
                        <div class="alert alert-pro alert-success mb-2">
                          <div class="alert-text">
                            <h6>Success</h6>
                            <p>{this.state.rejectClientSuccessMessage} </p>
                          </div>
                        </div>
                      ) : null}
                      <div id="revokeReasonItems">
                        <div className="tb-odr-id">Reason to reject</div>
                        <div className="card card-bordered card-preview mt-1">
                          <TextareaAutosize
                            maxrows={4}
                            name="reject_client_reason"
                            id="reject_client_reason"
                            aria-label=""
                            placeholder=""
                            value={this.state.reject_client_reason}
                            helperText="Reason to reject this submission"
                            onChange={this.handleChange}
                            style={{
                              width: "100%",
                              height: "100px",
                              borderColor: "rgba(0, 0, 0, 0.125)",
                              borderRadius: "4px",
                            }}
                            variant="outlined"
                          />
                        </div>
                        <div className="form-group text-right mt-2 mb-2">
                          {this.state.reject_client_button === true ? (
                            <button
                              class="btn btn-primary"
                              type="button"
                              disabled
                            >
                              <span
                                class="spinner-grow spinner-grow-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span> Loading... </span>
                            </button>
                          ) : (
                            <>
                              <button
                                type="button"
                                className="btn btn-light"
                                onClick={() => {
                                  this.closeModal("modalRejectionClientAlert");
                                }}
                                style={{ marginRight: "10px" }}
                              >
                                <em class="icon ni ni-shrink"></em>{" "}
                                <span>Cancel</span>
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => {
                                  this.reject_client_form();
                                }}
                                disabled={this.state.disabled}
                              >
                                <em class="icon ni ni-cross-circle"></em>{" "}
                                <span>Reject</span>
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* END REJECTION CLIENT MODAL */}
              {/* START CLIENT REJECTIONS MODAL */}
              <div class="modal fade" tabindex="-1" id="modalRejectionClients">
                <div
                  class="modal-dialog modal-dialog-top modal-xl"
                  role="document"
                >
                  <div class="modal-content">
                    <a
                      href="#"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em class="icon ni ni-cross"></em>
                    </a>
                    <div class="modal-header">
                      <h5 class="modal-title">
                        All Rejections{" "}
                        <sup className="text-primary">
                          {this.state.form_title}
                        </sup>
                      </h5>
                    </div>
                    <div class="modal-body">
                      {this.state.rejectClientErrorMessage !== "" ? (
                        <div class="alert alert-pro alert-danger mb-2">
                          <div class="alert-text">
                            <h6>Error</h6>
                            <p>{this.state.rejectClientErrorMessage} </p>
                          </div>
                        </div>
                      ) : null}
                      {this.state.rejectClientSuccessMessage !== "" ? (
                        <div class="alert alert-pro alert-success mb-2">
                          <div class="alert-text">
                            <h6>Success</h6>
                            <p>{this.state.rejectClientSuccessMessage} </p>
                          </div>
                        </div>
                      ) : null}
                      <div id="revokeReasonItems">
                        {this.state.rejectionLoader === true ? (
                          FormLoader()
                        ) : (
                          <>
                            <div>
                              <div className="card card-bordered">
                                <div className="card-inner-group">
                                  {this.state.allClientRejectionsListing &&
                                  this.state.allClientRejectionsListing.length >
                                    0 ? (
                                    <div className="card-inner">
                                      <div className="sp-plan-head">
                                        <h6 className="title">
                                          All Rejections
                                        </h6>
                                      </div>
                                      <div className="sp-plan-desc sp-plan-desc-mb">
                                        <table className="table table-bordered table-striped">
                                          <thead>
                                            <th>Form Title</th>
                                            <th>Rejection date</th>
                                            <th>Reason</th>
                                            <th>Submitted PDF</th>
                                          </thead>
                                          {this.state.allClientRejectionsListing.map(
                                            (general, index) => (
                                              <tbody
                                                key={`ind${index}`}
                                                className="text-dark"
                                              >
                                                <tr>
                                                  <td>
                                                    {this.state.form_title}
                                                  </td>
                                                  <td>
                                                    {this.format_date(
                                                      general.rejection_date
                                                    )}
                                                  </td>
                                                  <td>
                                                    {general.rejection_reason}
                                                  </td>
                                                  <td>
                                                    {general.is_submitted &&
                                                    general.is_submitted ===
                                                      1 ? (
                                                      <a
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                          this.downloadPdfGeneral(
                                                            general.pdf_name
                                                          )
                                                        }
                                                      >
                                                        <span class="badge badge-outline-dark">
                                                          View
                                                        </span>
                                                      </a>
                                                    ) : (
                                                      "---"
                                                    )}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            )
                                          )}
                                        </table>
                                      </div>
                                    </div>
                                  ) : (
                                    <div class="alert alert-pro alert-primary">
                                      <div class="alert-text">
                                        <h6>No Rejection</h6>
                                        <p>
                                          Rejections are not available against
                                          this submission.
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* END REJECTIONS CLIENT MODAL */}
              {/* START DELETE MODAL  */}
              <div
                className="modal fade"
                tabIndex="-1"
                id="modalDeleteGeneralAlert"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <a
                      style={{ cursor: "pointer" }}
                      className="close"
                      onClick={() => {
                        this.modalHideDel();
                      }}
                    >
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-body modal-body-lg text-center">
                      <div className="nk-modal">
                        <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-trash bg-success"></em>

                        <h4 className="nk-modal-title">Delete Submission?</h4>

                        <div className="nk-modal-text">
                          {this.state.errorMessageDelete !== "" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.errorMessageDelete}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.successMessageDelte !== "" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-success">
                                <div className="alert-text">
                                  <h4>Success</h4>
                                  <p>{this.state.successMessageDelte}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}

                          <div className="caption-text">
                            Are you sure you want to delete this submission
                          </div>
                        </div>
                        <div className="nk-modal-action">
                          <a
                            style={{ cursor: "pointer" }}
                            className="btn btn-lg btn-mw btn-primary"
                            onClick={() => {
                              this.modalHideDelGeneral();
                            }}
                          >
                            CANCEL
                          </a>
                          {"  "}
                          <a
                            style={{ cursor: "pointer" }}
                            className="btn btn-lg btn-mw btn-danger"
                            onClick={() => {
                              this.deleteGeneralSubmission();
                            }}
                            disabled={
                              this.state.disabled || this.state.isProcessing
                            } // disable button when processing
                          >
                            {this.state.isProcessing ? (
                              <span>DELETING...</span>
                            ) : (
                              <span>DELETE</span>
                            )}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* END DELETE MODAL  */}
              {/* START RESEND MODAL  */}
              <div className="modal fade" tabIndex="-1" id="modalResendAlert">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <a
                      style={{ cursor: "pointer" }}
                      className="close"
                      onClick={() => this.closeResendForm()}
                    >
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-body modal-body-lg text-center">
                      <div className="nk-modal">
                        <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-emails bg-success"></em>

                        <h4 className="nk-modal-title">Resend Form?</h4>
                        <div className="nk-modal-text">
                          {this.state.errorMessageResend !== "" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.errorMessageResend}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.successMessageResend !== "" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-success">
                                <div className="alert-text">
                                  <h4>Success</h4>
                                  <p>{this.state.successMessageResend}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div className="caption-text">
                            Are you sure you want to resend this form?
                          </div>
                        </div>
                        <div className="nk-modal-action">
                          {this.state.resendLoader === true ? (
                            <button
                              class="btn btn-lg btn-primary"
                              type="button"
                              disabled
                            >
                              <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span> Sending... </span>
                            </button>
                          ) : (
                            <>
                              <a
                                className="btn btn-lg btn-light"
                                style={{ cursor: "pointer", color: "#101924" }}
                                onClick={() => this.closeResendForm()}
                              >
                                CANCEL
                              </a>
                              {"  "}
                              <a
                                style={{ cursor: "pointer" }}
                                className={"btn btn-lg btn-mw btn-primary"}
                                onClick={() => {
                                  this.resendGeneralForm();
                                }}
                                disabled={this.state.disabled ? true : false}
                              >
                                Resend
                              </a>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* END RESEND MODAL  */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Forms);
